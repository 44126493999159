import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import ViewOrder from "./ViewOrder";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { orderApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function RequestOrderCancellation(props) {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const { id } = useParams();
  const [redirect, setRedirect] = React.useState(false);
  const [reason, setReason] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    orderApi
      .post(
        "/Orders/RequestCancellation",
        {
          id: id,
          reason: reason
        },
        {
          signal: abortSignal.current.signal
        }
      )
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };
  const handleReasonChanged = e =>
    setReason(e.target.value === "" ? null : e.target.value);

  const classes = useStyles();
  return redirect ? (
    <Redirect to="/admin/OrderCancellations/All" />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>REQUEST CANCELLATION</h4>
          </CardHeader>
          <CardBody>
            <p>
              <i>
                *Please review before request for cancellation. Once it is
                approved, all the voucher issued by the order cannot be used and
                it cannot be reversed.
              </i>
            </p>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Reason"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleReasonChanged,
                  value: reason === null ? "" : reason
                }}
              />
              <Button onClick={() => props.history.goBack()} value="Cancel">
                CANCEL
              </Button>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                REQUEST CANCELLATION
              </Button>
            </form>
            <ViewOrder id={id} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

RequestOrderCancellation.propTypes = {
  history: PropTypes.object
};
