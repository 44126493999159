import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/Order/create";

import { orderApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput";

const useStyles = makeStyles(styles);

export default function SendToEmail(props) {
  const abortSignal = useRef(new AbortController());
  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [body, setBody] = React.useState({
    email: "",
    password: ""
  });
  const [order, setOrder] = React.useState(null);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      orderApi
        .get("/Orders/" + id, {
          signal: abortSignal.current.signal
        })
        .then(data => {
          setOrder(data);
        })
        .catch(error => console.log(error));
    }

    return () => {
      setRedirect(false);
      setDisabled(false);
    };
  }, [id]);

  const handleSubmit = e => {
    e.preventDefault();
    if (order && order.voucherType) {
      setDisabled(true);
      if (order.voucherType.hasIdentificationNoRestriction) {
        orderApi
          .post("/Orders/" + id + "/SendToEmailSeasonPass", body, {
            signal: abortSignal.current.signal
          })
          .then(() => {
            setRedirect(true);
          })
          .catch(error => console.log(error))
          .finally(() => setDisabled(false));
      } else {
        orderApi
          .post("/Orders/" + id + "/SendToEmail", body, {
            signal: abortSignal.current.signal
          })
          .then(() => {
            setRedirect(true);
          })
          .catch(error => console.log(error))
          .finally(() => setDisabled(false));
      }
    } else {
      setDisabled(false);
    }
  };

  const handleEmailChanged = e => setBody({ ...body, email: e.target.value });

  const handlePasswordChanged = e =>
    setBody({ ...body, password: e.target.value });

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/Orders/Confirmed"} />
  ) : (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>SEND TO EMAIL</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>

              <GridContainer spacing={3}>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleEmailChanged,
                      value: body.email === null ? "" : body.email
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handlePasswordChanged,
                      value: body.password === null ? "" : body.password,
                      type: "password"
                    }}
                  />
                </GridItem>
              </GridContainer>
              <form onSubmit={handleSubmit}>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

SendToEmail.propTypes = {
  history: PropTypes.object
};
