import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
// @material-ui/core components
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { agentApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function RoleAvailablePermissionList(props) {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  let { id, reloadHandler, reload, isPredefined } = props;
  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [filter, setFilter] = React.useState({
    searchString: null
  });
  const [loading, setLoading] = React.useState(false);

  let defaultClaims = {
    claimTypes: []
  };
  const [claims, setClaims] = React.useState(defaultClaims);

  const classes = useStyles();

  useEffect(() => {
    const handleClaimsChanged = (checked, name) => {
      if (checked)
        setClaims({
          ...claims,
          claimTypes: claims.claimTypes.concat([name])
        });
      else
        setClaims({
          ...claims,
          claimTypes: claims.claimTypes.filter(v => v !== name)
        });
    };

    setLoading(true);
    agentApi
      .get("/Roles/" + id + "/Claims/availableClaims", {
        signal: abortSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              name: record,
              actions: (
                <div className="actions-right">
                  {!isPredefined && (
                    <Checkbox
                      checked={claims.claimTypes.includes(record)}
                      onChange={e =>
                        handleClaimsChanged(e.target.checked, record)
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  )}
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [id, filter, pagination, claims, reload, classes, isPredefined]);

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    agentApi
      .post("/Roles/" + id + "/Claims", claims, {
        signal: abortSignal.current.signal
      })
      .then(() => {
        setClaims(defaultClaims);
        setDisabled(false);
        reloadHandler();
      })
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>EXISTING PERMISSIONS</h4>
          </CardHeader>
          <CardBody>
            {!isPredefined && (
              <GridItem xs={3}>
                <form onSubmit={handleSubmit}>
                  <Button
                    disabled={disabled}
                    color="rose"
                    type="submit"
                    value="Submit"
                  >
                    Add
                  </Button>
                </form>
              </GridItem>
            )}
            <GridItem xs={3}>
              <CustomInput
                labelText="Search"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleSearchStringChanged,
                  value: filter.searchString === null ? "" : filter.searchString
                }}
              />
            </GridItem>
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  Header: "Permission",
                  accessor: "name"
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

RoleAvailablePermissionList.propTypes = {
  id: PropTypes.string,
  reloadHandler: PropTypes.func,
  reload: PropTypes.object,
  isPredefined: PropTypes.bool
};
