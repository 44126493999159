import React from "react";
import PropTypes from "prop-types";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";

export default function ViewNotificationConfig(props) {
  const { notificationConfig } = props;
  return (
    <>
      <CustomInput
        labelText="EventName"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: notificationConfig.eventName
        }}
      />
      <CustomInput
        labelText="Notification List"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: notificationConfig.notificationList.name
        }}
      />
    </>
  );
}

ViewNotificationConfig.propTypes = {
  notificationConfig: PropTypes.object
};
