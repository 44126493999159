import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function BankAccounts() {
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>SALES & MARKETING</h4>
          </CardHeader>
          <CardBody>
            <p>
              For issues relating to Sales & Marketing such as collaborations
              and pricing, please contact your Account manager, as follow:
            </p>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardBody>
                    <p>Name: Mr. Liu Nam Leong</p>
                    <p>Mobile: +65 8126 6866</p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardBody>
                    <p>Name: Ms Christie</p>
                    <p>Mobile: +65 9660 9383</p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardBody>
                    <p>Name: Mr Roby Lee</p>
                    <p>Mobile: +62 823 50809698</p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardBody>
                    <p>Name: Mr Jefry</p>
                    <p>Mobile: +62 813 72025839</p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardBody>
                    <p>Name: Miss Cita</p>
                    <p>Mobile: +62 821 70127225</p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <ul>
              <li>Email: sales@sindoferry.com.sg</li>
            </ul>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>TECHNICAL ASSISTANCE</h4>
          </CardHeader>
          <CardBody>
            <p>
              For issues relating to technical assistance such as unable to
              login, create order and seeing error messages, please contact us
              at:
            </p>
            <p>
              <i>
                Note: please provide your agent code and screenshots of the
                error messages / issues which you encountered.
              </i>
            </p>
            <ul>
              <li>Email: sys.support@sindoferry.com.sg</li>
            </ul>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              BOOKING & SEAT AVAILABILITY
            </h4>
          </CardHeader>
          <CardBody>
            <p>
              For issues relating to bookings such as block seats, checking seat
              availability, please contact our call centre at:
            </p>
            <ul>
              <li>Email: callcentre@sindoferry.com.sg</li>
            </ul>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader>
                    <h4>Singapore Contact No.</h4>
                  </CardHeader>
                  <CardBody>
                    <p>Whatsapp: +65 9011 3061</p>
                    <p>Hotline: +65 6331 4123</p>
                    <p>Everyday 07:00 - 21:00</p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card>
                  <CardHeader>
                    <h4>Batam Contact No.</h4>
                  </CardHeader>
                  <CardBody>
                    <p>Whatsapp: +62 811 6663 061</p>
                    <p>Hotline: +62 778 4892 888</p>
                    <p>Everyday 08:00 - 17:00</p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
