export function GetPolicyActions() {
  return [
    {
      key: 0,
      description: "Manual Approval"
    },
    {
      key: 1,
      description: "Approve"
    },
    {
      key: 2,
      description: "Reject"
    }
  ];
}

export function GetPolicyActionDescription(value) {
  let policyAction = GetPolicyActions().find(item => item.key === value);
  return policyAction.description;
}
