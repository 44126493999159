import React from "react";
import PropTypes from "prop-types";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { GetBookingCancellationStatusDescription } from "enums/BookingCancellationStatus";
import { formatDateTime } from "functions/Helper";
import { GetFeeCalculationTypeDescription } from "enums/FeeCalculationType";
import { GetPolicyActionDescription } from "enums/PolicyAction";

const useStyles = makeStyles(styles);

export default function ViewBookingCancellation(props) {
  const { bookingCancellation, history, currency } = props;
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              BOOKING CANCELLATION DETAILS
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => history.goBack()} value="Back">
              BACK
            </Button>
            <CustomInput
              labelText="Cancellation No"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: bookingCancellation.no
              }}
            />
            <CustomInput
              labelText="Booking No"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: bookingCancellation.booking.no
              }}
            />
            <CustomInput
              labelText="Reason"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: bookingCancellation.reason
              }}
            />
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <CustomInput
                  labelText="Currency"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: currency
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <CustomInput
                  labelText="Refund Amount"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: bookingCancellation.refundAmount.toLocaleString()
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <CustomInput
                  labelText="Total Fee"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: bookingCancellation.totalFee.toLocaleString()
                  }}
                />
              </GridItem>
            </GridContainer>
            {bookingCancellation.status == 2 && (
              <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                  <CustomInput
                    labelText="Policy Action"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        bookingCancellation.action != null
                          ? GetPolicyActionDescription(
                              bookingCancellation.action
                            )
                          : ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <CustomInput
                    labelText="Fee Calculation Type"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: GetFeeCalculationTypeDescription(
                        bookingCancellation.feeCalculation.type
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <CustomInput
                    labelText="Value"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: bookingCancellation.feeCalculation.value.toLocaleString()
                    }}
                  />
                </GridItem>
              </GridContainer>
            )}
            <CustomInput
              labelText="Status"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: GetBookingCancellationStatusDescription(
                  bookingCancellation.status
                )
              }}
            />
            {bookingCancellation.status === 0 && (
              <CustomInput
                labelText="Created At"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: formatDateTime(
                    new Date(bookingCancellation.transactionTime + "Z")
                  )
                }}
              />
            )}
            {bookingCancellation.status === 1 && (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Submitted At"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(
                        new Date(bookingCancellation.submittedAt + "Z")
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Submitted By"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: bookingCancellation.submittedBy
                    }}
                  />
                </GridItem>
              </GridContainer>
            )}
            {bookingCancellation.status === 2 && (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Approved At"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(
                        new Date(bookingCancellation.approvedAt + "Z")
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Approved By"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: bookingCancellation.approvedBy
                    }}
                  />
                </GridItem>
              </GridContainer>
            )}
            {bookingCancellation.status === 3 && (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Rejected At"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(
                        new Date(bookingCancellation.rejectedAt + "Z")
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Rejected By"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: bookingCancellation.rejectedBy
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Rejected Reason"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: bookingCancellation.rejectedReason
                    }}
                  />
                </GridItem>
              </GridContainer>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

ViewBookingCancellation.propTypes = {
  bookingCancellation: PropTypes.object,
  history: PropTypes.object,
  currency: PropTypes.string
};
