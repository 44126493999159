import React, { useEffect, useRef } from "react";
import Moment from "moment";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TagsInput from "react-tagsinput";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import ViewVoucherType from "views/VoucherType/ViewVoucherType";

import styles from "assets/jss/material-dashboard-pro-react/views/Order/create";

import {
  coreApi,
  masterApi,
  orderApi,
  creditMonitoringApi
} from "functions/apis";

import { token } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function CreateOrder(props) {
  const abortSignal = useRef(new AbortController());
  const [agentCredit, setAgentCredit] = React.useState(null);

  const [agentVoucherTypeList, setAgentVoucherTypeList] = React.useState([]);
  const [routeList, setRouteList] = React.useState([]);
  const [routeLists, setRouteLists] = React.useState([]);
  const [redirect, setRedirect] = React.useState(false);

  useEffect(() => {
    orderApi
      .get("/AgentVoucherTypes", {
        signal: abortSignal.current.signal,
        params: {
          filter: {
            searchString: null,
            currentActive: true
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setAgentVoucherTypeList(data.records))
      .catch(error => console.log(error));

    if (
      token["creditmonitoring.agents.read"] === "allowed" ||
      token.is_admin === "True"
    )
      creditMonitoringApi
        .get("/Agents", {
          signal: abortSignal.current.signal
        })
        .then(data => setAgentCredit(data))
        .catch(error => console.log(error));
    if (token["master.routes.read"] === "allowed" || token.is_admin === "True")
      masterApi
        .get("/Routes", {
          signal: abortSignal.current.signal,
          params: {
            filter: {
              searchString: null
            },
            pagination: {
              pageIndex: 0,
              pageSize: 0
            }
          }
        })
        .then(data => setRouteList(data.records))
        .catch(error => console.log(error));
    return () => abortSignal.current.abort();
  }, []);
  const [agentVoucherTypeID, setAgentVoucherTypeID] = React.useState(null);
  const [agentVoucherType, setAgentVoucherType] = React.useState(null);
  const [voucherTypeID, setVoucherTypeID] = React.useState(null);
  const [
    allowedIdentificationNos,
    setAllowedIdentificationNos
  ] = React.useState([]);
  const [quantity, setQuantity] = React.useState(1);
  const [remarks, setRemarks] = React.useState(null);

  const [voucherType, setVoucherType] = React.useState(null);
  const [issuanceTrips, setIssuanceTrips] = React.useState([]);
  const [scheduleCodeLists, setScheduleCodeLists] = React.useState([]);

  const handleVoucherTypeIDChanged = e => {
    if (agentVoucherTypeID !== e.target.value) {
      setAgentVoucherTypeID(e.target.value);

      if (
        token["order.agent_voucher_types.read_with_billing_price"] ||
        token.is_admin === "True"
      )
        orderApi
          .get("/AgentVoucherTypes/" + e.target.value + "/WithBillingPrice", {
            signal: abortSignal.current.signal
          })
          .then(res => setAgentVoucherType(res))
          .catch(error => console.log(error));

      let vid = agentVoucherTypeList.find(
        record => record.id === e.target.value
      ).voucherType.id;
      setVoucherTypeID(vid);
      if (token["order.voucher_types.read"] || token.is_admin === "True") {
        orderApi
          .get("/VoucherTypes/" + vid, {
            signal: abortSignal.current.signal
          })
          .then(res => {
            setVoucherType(res);
            let i;
            let tmpRouteLists = [...routeLists];
            for (i = 0; i < res.trips.length; i++) {
              let trip = res.trips[i];
              let tmpRouteList = [...routeList];
              if (trip.hasSectorRestriction) {
                tmpRouteList = tmpRouteList.filter(
                  route => route.sector.id === trip.allowedSector.id
                );
              }
              if (trip.hasRouteRestriction) {
                let allowedRouteIDs = trip.allowedRoutes.map(
                  allowedRoute => allowedRoute.route.id
                );
                tmpRouteList = tmpRouteList.filter(route =>
                  allowedRouteIDs.includes(route.id)
                );
              }
              tmpRouteLists[i] = tmpRouteList;
            }
            setRouteLists(tmpRouteLists);
            setIssuanceTrips(
              res.trips.map(trip => {
                return {
                  voucherTypeTripID: trip.id,
                  hasTripRestriction: false,
                  routeID: null,
                  tripDate: null,
                  scheduleCode: null
                };
              })
            );
          })
          .catch(error => console.log(error));
      }
    }
    e.preventDefault();
  };
  const handleAllowedIdentificationNosChanged = allowedIdentificationNos =>
    setAllowedIdentificationNos(allowedIdentificationNos);

  const handleQuantityChanged = e => {
    let newQuantity = parseInt(e.target.value);
    if (newQuantity !== quantity) setQuantity(newQuantity);
    e.preventDefault();
  };

  const handleRemarksChanged = e => setRemarks(e.target.value);

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    orderApi
      .post(
        "/Orders",
        {
          voucherTypeID: voucherTypeID,
          quantity: quantity,
          remarks: remarks,
          allowedIdentificationNos: allowedIdentificationNos,
          issuanceTrips: issuanceTrips.filter(
            issuanceTrip => issuanceTrip.hasTripRestriction === true
          )
        },
        {
          signal: abortSignal.current.signal
        }
      )
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const handleHasTripRestrictionChanged = (index, newValue) => {
    let newIssuanceTrips = [...issuanceTrips];
    newIssuanceTrips[index].hasTripRestriction = newValue;
    if (!newValue) {
      newIssuanceTrips[index].routeID = null;
      newIssuanceTrips[index].tripDate = null;
      newIssuanceTrips[index].scheduleCode = null;
    }
    setIssuanceTrips(newIssuanceTrips);
  };

  let updateScheduleCodeList = (index, newIssuanceTrip) => {
    if (newIssuanceTrip.routeID !== null && newIssuanceTrip.tripDate !== null) {
      let selectedRoute = routeList.find(
        route => route.id === newIssuanceTrip.routeID
      );
      let ports = selectedRoute.code.split(" - ");
      let embarkationPort = ports[0];
      let destinationPort = ports[1];
      if (embarkationPort === "TMFT") embarkationPort = "TMF";
      if (destinationPort === "TMFT") destinationPort = "TMF";
      coreApi
        .get("/Trips/GetTripWeb", {
          signal: abortSignal.current.signal,
          params: {
            embarkation: embarkationPort,
            destination: destinationPort,
            tripDate: Moment(newIssuanceTrip.tripDate).format("yyyyMMDD"),
            paxCount: 1,
            agentID: token.aid
          }
        })
        .then(data => {
          let newScheduleCodeLists = [...scheduleCodeLists];
          newScheduleCodeLists[index] = data.data.map(record => {
            return {
              ...record,
              tripTime: record.departureTime.replace(":", "")
            };
          });
          setScheduleCodeLists(newScheduleCodeLists);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const handleRouteIDChanged = (index, newValue) => {
    if (newValue === "") newValue = null;
    let newIssuanceTrips = [...issuanceTrips];
    newIssuanceTrips[index].routeID = newValue;
    newIssuanceTrips[index].scheduleCode = null;
    setIssuanceTrips(newIssuanceTrips);
    updateScheduleCodeList(index, newIssuanceTrips[index]);
  };

  const handleTripDateChanged = (index, newValue) => {
    if (newValue === "") newValue = null;
    let newIssuanceTrips = [...issuanceTrips];
    newIssuanceTrips[index].tripDate = newValue;
    newIssuanceTrips[index].scheduleCode = null;
    setIssuanceTrips(newIssuanceTrips);
    updateScheduleCodeList(index, newIssuanceTrips[index]);
  };

  const handleScheduleCodeChanged = (index, newValue) => {
    if (newValue === "") newValue = null;
    let newIssuanceTrips = [...issuanceTrips];
    newIssuanceTrips[index].scheduleCode = newValue;
    setIssuanceTrips(newIssuanceTrips);
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/Orders/Pending"} />
  ) : (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>CREATE ORDER</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selVoucherType"
                    className={classes.selectLabel}
                  >
                    Voucher Type
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={
                      agentVoucherTypeID === null ? "" : agentVoucherTypeID
                    }
                    onChange={handleVoucherTypeIDChanged}
                    inputProps={{
                      name: "selVoucherType",
                      id: "selVoucherType"
                    }}
                  >
                    {agentVoucherTypeList.map(record => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={record.id}
                          key={record.id}
                        >
                          <p>
                            <b>{record.voucherType.code}</b>
                          </p>
                          {record.voucherType.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {voucherType !== null &&
                  voucherType.hasIdentificationNoRestriction && (
                    <>
                      <InputLabel className={classes.selectLabel}>
                        Allowed Identification No(s){" "}
                      </InputLabel>
                      <small className={classes.warning}>
                        (Max allowed is{" "}
                        {voucherType.maxAllowedIdentificationNoCount})
                      </small>
                      <br />
                      <TagsInput
                        value={allowedIdentificationNos}
                        onChange={handleAllowedIdentificationNosChanged}
                        maxTags={voucherType.maxAllowedIdentificationNoCount}
                        inputProps={{
                          className:
                            "react-tagsinput-input " + classes.tagInput,
                          placeholder: "Press Tab to Add"
                        }}
                        tagProps={{ className: "react-tagsinput-tag info" }}
                      />
                    </>
                  )}
                <CustomInput
                  labelText="Quantity"
                  id="numQuantity"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: handleQuantityChanged,
                    value: quantity
                  }}
                />
                {voucherType !== null &&
                  voucherType.hasMinimumIssuanceQuantityRestriction && (
                    <>
                      <small className={classes.warning}>
                        (Min quantity is {voucherType.minimumIssuanceQuantity})
                      </small>
                      <br />
                    </>
                  )}
                {voucherType !== null &&
                  voucherType.hasMaximumIssuanceQuantityRestriction && (
                    <small className={classes.warning}>
                      (Max quantity is {voucherType.maximumIssuanceQuantity})
                    </small>
                  )}
                <CustomInput
                  labelText="Remarks"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleRemarksChanged,
                    value: remarks === null ? "" : remarks
                  }}
                />
                {agentCredit !== null && agentVoucherType !== null && (
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                      <CustomInput
                        labelText="Billing Currency"
                        disabled
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value:
                            agentCredit.billingCurrency === null
                              ? ""
                              : agentCredit.billingCurrency.code
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <CustomInput
                        labelText="Billing Price"
                        id="billingPrice"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value:
                            agentVoucherType != null
                              ? agentVoucherType.billingPrice.toLocaleString()
                              : 0
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <CustomInput
                        labelText="Total"
                        id="total"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: (
                            (agentVoucherType != null
                              ? agentVoucherType.billingPrice
                              : 0) * quantity
                          ).toLocaleString()
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                )}
                <GridContainer>
                  {voucherType !== null &&
                    issuanceTrips.map((issuanceTrip, index) => {
                      return (
                        <GridItem md={4} lg={4} key={index}>
                          <Card>
                            <CardHeader>
                              <b>{voucherType.trips[index].description}</b>
                            </CardHeader>
                            <CardBody>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={issuanceTrip.hasTripRestriction}
                                    onChange={e =>
                                      handleHasTripRestrictionChanged(
                                        index,
                                        e.target.checked
                                      )
                                    }
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  root: classes.labelRoot
                                }}
                                label="Has Trip Restriction"
                              />
                              {issuanceTrip.hasTripRestriction === true && (
                                <>
                                  <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                  >
                                    <InputLabel
                                      htmlFor={"selRoute" + index}
                                      className={classes.selectLabel}
                                    >
                                      Route
                                    </InputLabel>
                                    <Select
                                      MenuProps={{
                                        className: classes.selectMenu
                                      }}
                                      classes={{
                                        select: classes.select
                                      }}
                                      value={
                                        issuanceTrip.routeID === null
                                          ? ""
                                          : issuanceTrip.routeID
                                      }
                                      onChange={e =>
                                        handleRouteIDChanged(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      inputProps={{
                                        name: "selRoute" + index,
                                        id: "selRoute" + index
                                      }}
                                    >
                                      {typeof routeLists[index] !==
                                        "undefined" &&
                                        routeLists[index].map(record => {
                                          return (
                                            <MenuItem
                                              classes={{
                                                root: classes.selectMenuItem,
                                                selected:
                                                  classes.selectMenuItemSelected
                                              }}
                                              value={record.id}
                                              key={record.id}
                                            >
                                              <p>
                                                <b>{record.code}</b>
                                              </p>
                                              {record.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </FormControl>
                                  <FormControl fullWidth>
                                    <Datetime
                                      value={
                                        issuanceTrip.tripDate === null
                                          ? ""
                                          : issuanceTrip.tripDate
                                      }
                                      utc={true}
                                      onChange={e =>
                                        handleTripDateChanged(index, e)
                                      }
                                      timeFormat={false}
                                      dateFormat={"DD/MM/YYYY"}
                                      inputProps={{
                                        placeholder: "Trip Date"
                                      }}
                                    />
                                  </FormControl>
                                  <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                  >
                                    <InputLabel
                                      htmlFor={"selScheduleCodes" + index}
                                      className={classes.selectLabel}
                                    >
                                      Schedule Code
                                    </InputLabel>
                                    <Select
                                      MenuProps={{
                                        className: classes.selectMenu
                                      }}
                                      classes={{
                                        select: classes.select
                                      }}
                                      value={
                                        issuanceTrip.scheduleCode === null
                                          ? ""
                                          : issuanceTrip.scheduleCode
                                      }
                                      onChange={e =>
                                        handleScheduleCodeChanged(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      inputProps={{
                                        name: "selScheduleCode" + index,
                                        id: "selScheduleCode" + index
                                      }}
                                    >
                                      {scheduleCodeLists[index] &&
                                        scheduleCodeLists[index].map(record => {
                                          return (
                                            <MenuItem
                                              classes={{
                                                root: classes.selectMenuItem,
                                                selected:
                                                  classes.selectMenuItemSelected
                                              }}
                                              value={record.tripTime}
                                              key={record.tripTime}
                                            >
                                              {record.tripTime}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </FormControl>
                                </>
                              )}
                            </CardBody>
                          </Card>
                        </GridItem>
                      );
                    })}
                </GridContainer>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {voucherType !== null && <ViewVoucherType voucherType={voucherType} />}
    </>
  );
}

CreateOrder.propTypes = {
  history: PropTypes.object
};
