import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { agentApi } from "functions/apis";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);

export default function NotificationConfigDetail(props) {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const { id } = useParams();
  const [notificationConfig, setNotificationConfig] = React.useState(null);

  useEffect(() => {
    agentApi
      .get("/NotificationConfigs/" + id, {
        signal: abortSignal.current.signal
      })
      .then(res => setNotificationConfig(res))
      .catch(error => console.log(error));
  }, [id]);

  const classes = useStyles();
  return (
    notificationConfig !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>NOTIFICATION LIST</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <CustomInput
                labelText="EventName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: notificationConfig.eventName
                }}
              />
              <CustomInput
                labelText="Notification List"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: notificationConfig.notificationList.name
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

NotificationConfigDetail.propTypes = {
  history: PropTypes.object
};
