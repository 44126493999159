import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";

import CancellationApprovedOrderList from "views/OrderCancellation/CancellationApprovedOrderList.js";
import CancellationRejectedOrderList from "views/OrderCancellation/CancellationRejectedOrderList.js";
import CancellationRequestedOrderList from "views/OrderCancellation/CancellationRequestedOrderList.js";
import AllOrderList from "views/OrderCancellation/AllOrderList.js";
import RequestOrderCancellation from "views/OrderCancellation/RequestOrderCancellation.js";
import WithdrawOrderCancellationRequest from "views/OrderCancellation/WithdrawOrderCancellationRequest.js";
import OrderCancellationDetail from "views/OrderCancellation/OrderCancellationDetail.js";

import { token } from "functions/Helper";

const orderCancellation = () => {
  let views = [];

  if (token["order.orders.read"] === "allowed" || token.is_admin === "True")
    views = views.concat([
      {
        path: "/OrderCancellations/All",
        name: "All",
        mini: "AL",
        component: AllOrderList,
        layout: "/admin"
      },
      {
        path: "/OrderCancellations/Requested",
        name: "Requested",
        mini: "RO",
        component: CancellationRequestedOrderList,
        layout: "/admin"
      },
      {
        path: "/OrderCancellations/Approved",
        name: "Approved",
        mini: "AO",
        component: CancellationApprovedOrderList,
        layout: "/admin"
      },
      {
        path: "/OrderCancellations/Rejected",
        name: "Rejected",
        mini: "RE",
        component: CancellationRejectedOrderList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Order Cancellations",
        icon: AssignmentLateIcon,
        state: "orderCancellationCollapse",
        views: views
      }
    ];
  return [];
};

const orderCancellationHidden = () => {
  let views = [];
  if (token["order.orders.read"] === "allowed" || token.is_admin === "True")
    views.push({
      path: "/OrderCancellations/Detail",
      name: "Order Cancellation Detail",
      component: OrderCancellationDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["order.orders.request_cancellation"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/OrderCancellations/Request",
        name: "Request Cancellation",
        component: RequestOrderCancellation,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);

  if (
    token["order.orders.withdraw_cancellation_request"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/OrderCancellations/WithdrawRequest",
        name: "Withdraw Request",
        component: WithdrawOrderCancellationRequest,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Order Cancellation Hidden",
        state: "orderCancellationHiddenCollapse",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { orderCancellation, orderCancellationHidden };
