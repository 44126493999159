import DashboardIcon from "@material-ui/icons/Dashboard";
import Dashboard from "views/Dashboard/Dashboard.js";

const dashboard = () => {
  return [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: Dashboard,
      layout: "/admin"
    }
  ];
};

export { dashboard };
