import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import CreateOrder from "views/Order/CreateOrder.js";
import QuickCreateOrder from "views/Order/QuickCreateOrder.js";
import PendingOrderList from "views/Order/PendingOrderList";
import PaidOrderList from "views/Order/PaidOrderList";
import ConfirmedOrderList from "views/Order/ConfirmedOrderList";
import DeleteOrder from "views/Order/DeleteOrder";
import ConfirmOrder from "views/Order/ConfirmOrder";
import IssueVoucherOrder from "views/Order/IssueVoucherOrder";
import PrintVoucherIssuance from "views/Order/PrintVoucherIssuance";

import { token } from "functions/Helper";
import SendToEmail from "views/Order/SendToEmail";

const order = () => {
  let views = [];
  if (token["order.orders.read"] === "allowed" || token.is_admin === "True")
    views = views.concat([
      {
        path: "/Orders/Pending",
        name: "Pending",
        mini: "PE",
        component: PendingOrderList,
        layout: "/admin"
      },
      {
        path: "/Orders/Paid",
        name: "Paid",
        mini: "PA",
        component: PaidOrderList,
        layout: "/admin"
      },
      {
        path: "/Orders/Confirmed",
        name: "Confirmed",
        mini: "CO",
        component: ConfirmedOrderList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Orders",
        icon: MonetizationOnIcon,
        state: "orderCollapse",
        views: views
      }
    ];
  return [];
};

const orderHidden = () => {
  let views = [];
  if (token["order.orders.write"] === "allowed" || token.is_admin === "True")
    views = views.concat([
      {
        path: "/Order/Create",
        name: "Create Order",
        component: CreateOrder,
        layout: "/admin"
      },
      {
        path: "/Order/Delete",
        name: "Delete Order",
        component: DeleteOrder,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/Order/SendToEmail",
        name: "Create Order",
        component: SendToEmail,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (
    (token["order.orders.process_payment"] === "allowed" &&
      token["order.orders.issue_voucher"] === "allowed") ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/Order/Confirm",
        name: "Confirm Order",
        mini: "CO",
        component: ConfirmOrder,
        layout: "/admin",
        hideInSidebar: true,
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/Order/QuickCreate",
        name: "Create & Confirm Order",
        mini: "CO",
        component: QuickCreateOrder,
        layout: "/admin",
        hideInSidebar: true
      }
    ]);
  if (
    token["order.orders.issue_voucher"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/Order/IssueVoucher",
        name: "Issue Voucher",
        mini: "CO",
        component: IssueVoucherOrder,
        layout: "/admin",
        hideInSidebar: true,
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/Order/PrintVoucherIssuance",
        name: "Print VoucherIssuance",
        mini: "PV",
        component: PrintVoucherIssuance,
        layout: "/empty",
        hideInSidebar: true,
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Order Hidden",
        state: "orderCollapse",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { order, orderHidden };
