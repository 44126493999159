import React, { useEffect, useRef } from "react";
import { Redirect, useParams } from "react-router-dom";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import ViewTopUp from "views/TopUp/ViewTopUp.js";

import { creditMonitoringApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function UploadPaymentReceipt(props) {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);
  const { id } = useParams();

  const [topUp, setTopUp] = React.useState(null);

  useEffect(() => {
    creditMonitoringApi
      .get("/TopUps/" + id, {
        signal: abortSignal.current.signal
      })
      .then(data => setTopUp(data))
      .catch(error => console.log(error));
  }, [id]);

  const [state, setState] = React.useState({
    file: null
  });

  const [redirect, setRedirect] = React.useState(false);

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    let data = new FormData();
    data.append("file", state.file);
    creditMonitoringApi
      .post("TopUps/PaymentReceipt/" + id, data, {
        signal: abortSignal.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const handleUpload = e =>
    setState({
      file: e.target.files[0]
    });

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/TopUps/Pending"} />
  ) : (
    topUp !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>UPLOAD PAYMENT RECEIPT</h4>
            </CardHeader>
            <CardBody>
              {topUp.hasPaymentReceiptFile ? (
                <p>
                  <i>
                    *This top up already has Payment Receipt File uploaded.
                    Uploading again will replace the old one.
                  </i>
                </p>
              ) : (
                <p>
                  <i>*Please upload your payment receipt.</i>
                </p>
              )}
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleUpload,
                        type: "file"
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <Button onClick={() => props.history.goBack()} value="Back">
                  BACK
                </Button>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
              <ViewTopUp topUp={topUp} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

UploadPaymentReceipt.propTypes = {
  history: PropTypes.object
};
