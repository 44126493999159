import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import { bookingApi, creditMonitoringApi } from "functions/apis";
import View from "./View";
import ViewPassengerList from "./ViewPassengerList";
import { token } from "functions/Helper";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);

export default function Delete(props) {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const { id } = useParams();
  const [booking, setBooking] = React.useState(null);
  const loadPricing =
    token["booking.bookings.read_with_pricing"] === "allowed" ||
    token.is_admin === "True";

  const [agentCredit, setAgentCredit] = React.useState(null);

  useEffect(() => {
    if (loadPricing) {
      bookingApi
        .get(`/Bookings/${id}/WithPricing`, {
          signal: abortSignal.current.signal
        })
        .then(data => setBooking(data))
        .catch(error => console.log(error));

      if (
        token["creditmonitoring.agents.read"] === "allowed" ||
        token.is_admin === "True"
      )
        creditMonitoringApi
          .get("/Agents", {
            signal: abortSignal.current.signal
          })
          .then(data => setAgentCredit(data))
          .catch(error => console.log(error));
    } else {
      bookingApi
        .get(`/Bookings/${id}`, {
          signal: abortSignal.current.signal
        })
        .then(data => setBooking(data))
        .catch(error => console.log(error));
    }
  }, [id]);

  const [redirect, setRedirect] = React.useState(false);

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .delete("/Bookings/" + id, {
        signal: abortSignal.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to="/admin/Bookings/Pending" />
  ) : (
    booking !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>DELETE CONFIRMATION</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <p>Delete this booking?</p>
                <Button onClick={() => props.history.goBack()} value="Cancel">
                  CANCEL
                </Button>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  DELETE
                </Button>
              </form>
            </CardBody>
          </Card>
          <View booking={booking} history={props.history} />
          <ViewPassengerList
            id={booking.id}
            currency={
              agentCredit !== null && agentCredit.billingCurrency !== null
                ? agentCredit.billingCurrency.code
                : ""
            }
          />
        </GridItem>
      </GridContainer>
    )
  );
}

Delete.propTypes = {
  history: PropTypes.object
};
