export function GetTopUpStatuses() {
  return [
    {
      key: 0,
      description: "Pending"
    },
    {
      key: 1,
      description: "Approved"
    },
    {
      key: 2,
      description: "Rejected"
    }
  ];
}

export function GetTopUpStatusDescription(value) {
  let topUpStatus = GetTopUpStatuses().find(item => item.key === value);
  return topUpStatus.description;
}
