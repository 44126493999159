import {
  cardTitle,
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";

import {
  defaultFont,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const passengerList = theme => ({
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  selectLabel: {
    ...customSelectStyle.selectLabel,
    paddingTop: 10
  },
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex"
  },
  mrAuto: {
    marginRight: "auto"
  },
  mlAuto: {
    marginLeft: "auto"
  },
  cardHeader: {
    zIndex: "3"
  },
  cardSubtitle: {
    ...defaultFont,
    color: grayColor[0],
    fontSize: "14px",
    margin: "0 0 10px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  marginRight: {
    marginRight: "5px"
  },
  modalSectionTitle: {
    marginTop: "30px"
  },
  ...modalStyle(theme),

  autoComplete: {
    paddingBottom: 10
  },
  datetime: {
    paddingBottom: 10
  },
  warning: {
    color: "red"
  }
});

export default passengerList;
