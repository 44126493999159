export function GetTopUpMethods() {
  return [
    {
      key: 0,
      description: "Manual"
    },
    {
      key: 1,
      description: "Credit Card"
    },
    {
      key: 2,
      description: "Grab Pay"
    },
    {
      key: 2001,
      description: "Google Pay"
    },
    {
      key: 2002,
      description: "Apple Pay"
    },
    {
      key: 2003,
      description: "Alipay"
    },
    {
      key: 2004,
      description: "Wechat Pay"
    },
    {
      key: 2005,
      description: "Pay Now"
    },
    {
      key: 3,
      description: "Midtrans"
    },
    {
      key: 3001,
      description: "Gopay"
    },
    {
      key: 3002,
      description: "Bank Transfer"
    },
    {
      key: 3003,
      description: "Convenience Stores"
    },
    {
      key: 3004,
      description: "ShopeePay"
    },
    {
      key: 3005,
      description: "Bank Transfer - Permata"
    },
    {
      key: 3006,
      description: "Bank Transfer - BCA"
    },
    {
      key: 3007,
      description: "Bank Transfer - BNI"
    },
    {
      key: 3008,
      description: "Bank Transfer - BRI"
    },
    {
      key: 3009,
      description: "eChannel"
    },
    {
      key: 3010,
      description: "Bank Transfer - Other Bank"
    },
    {
      key: 3011,
      description: "Danamon Online"
    },
    {
      key: 3012,
      description: "Mandiri Clickpay"
    },
    {
      key: 3013,
      description: "CIMB Clicks"
    },
    {
      key: 3014,
      description: "KlikBca"
    },
    {
      key: 3015,
      description: "BCA Klikpay"
    },
    {
      key: 3016,
      description: "BRI ePay"
    },
    {
      key: 3017,
      description: "XL Tunai"
    },
    {
      key: 3018,
      description: "Indosat Dompetku"
    },
    {
      key: 3019,
      description: "Kiosan"
    },
    {
      key: 3020,
      description: "Indomaret"
    },
    {
      key: 3021,
      description: "Alfamart"
    },
    {
      key: 3022,
      description: "Akulaku"
    },
    {
      key: 3023,
      description: "QRIS"
    }
  ];
}

export function GetSGDTopUpMethods() {
  return [
    {
      key: 0,
      description: "Manual"
    },
    {
      key: 1,
      description: "Credit Card"
    },
    {
      key: 2,
      description: "Grab Pay"
    },
    {
      key: 2003,
      description: "Alipay"
    },
    {
      key: 2004,
      description: "Wechat Pay"
    },
    {
      key: 2005,
      description: "Pay Now"
    }
  ];
}

export function GetIDRTopUpMethods() {
  return [
    {
      key: 0,
      description: "Manual"
    },
    {
      key: 1,
      description: "Credit Card"
    },
    {
      key: 3001,
      description: "Gopay"
    },
    {
      key: 3002,
      description: "Bank Transfer"
    },
    {
      key: 3023,
      description: "QRIS"
    }
  ];
}

export function GetTopUpMethodDescription(value) {
  let topUpMethod = GetTopUpMethods().find(item => item.key === value);
  return topUpMethod.description;
}
