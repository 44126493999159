import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import ViewUser from "./ViewUser";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { agentApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function UpdateLoginUser(props) {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const { id } = useParams();
  const [user, setUser] = React.useState(null);
  const [redirect, setRedirect] = React.useState(false);
  useEffect(() => {
    agentApi
      .get("/Users/" + id, {
        signal: abortSignal.current.signal
      })
      .then(data => setUser(data))
      .catch(error => console.log(error));
  }, [id]);

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    agentApi
      .post(
        "/Users/UpdateLogin",
        {
          id: id
        },
        {
          signal: abortSignal.current.signal
        }
      )
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to="/admin/UserList" />
  ) : (
    user !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>UPDATE USER LOGIN</h4>
            </CardHeader>
            <CardBody>
              <p>Update this User Login?</p>
              <i>
                *It will cause this user to automatically logout from system and
                demand them to re-login to reload their permissions.
              </i>
              <form onSubmit={handleSubmit}>
                <Button onClick={() => props.history.goBack()} value="Cancel">
                  Cancel
                </Button>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  UPDATE LOGIN
                </Button>
              </form>
              <ViewUser user={user} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

UpdateLoginUser.propTypes = {
  history: PropTypes.object
};
