import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Assignment from "@material-ui/icons/Assignment";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CardIcon from "components/Card/CardIcon.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { orderApi } from "functions/apis";

import { formatDateTime, token } from "functions/Helper";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function PaidOrderList() {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [filter, setFilter] = React.useState({
    searchString: null,
    status: 2,
    sort: 9 //TransactionTime DESC
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    orderApi
      .get("/Orders", {
        signal: abortSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              allowedIdentificationNos:
                record.allowedIdentificationNos === null
                  ? ""
                  : record.allowedIdentificationNos.join(),
              actions: (
                <div className="actions-right">
                  {record.isCancelled === false &&
                    (token["order.orders.issue_voucher"] === "allowed" ||
                      token.is_admin === "True") && (
                      <Link to={"/admin/Order/IssueVoucher/" + record.id}>
                        <Button color="info" className="like">
                          <Check /> ISSUE VOUCHER
                        </Button>
                      </Link>
                    )}
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>PAID ORDER</h4>
          </CardHeader>
          <CardBody>
            <GridItem xs={3}>
              <CustomInput
                labelText="Search"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleSearchStringChanged,
                  value: filter.searchString === null ? "" : filter.searchString
                }}
              />
            </GridItem>
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  Header: "No",
                  accessor: "no"
                },
                {
                  id: "paymentProcessedAt",
                  Header: "Paid Date",
                  accessor: d =>
                    formatDateTime(new Date(d.paymentProcessedAt + "Z"))
                },
                {
                  Header: "Quantity",
                  accessor: "quantity"
                },
                {
                  Header: "Remarks",
                  accessor: "remarks"
                },
                {
                  Header: "Allowed Identification Nos",
                  accessor: "allowedIdentificationNos"
                },
                {
                  Header: "Created By",
                  accessor: "createdBy"
                },
                {
                  id: "isCancelled",
                  Header: "Is Cancelled",
                  accessor: d => (d.isCancelled === true ? "Yes" : "No")
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
