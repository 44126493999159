import AddIcon from "@material-ui/icons/Add";

import PendingTopUpList from "views/TopUp/PendingTopUpList.js";
import ApprovedTopUpList from "views/TopUp/ApprovedTopUpList.js";
import RejectedTopUpList from "views/TopUp/RejectedTopUpList.js";
import CreateTopUp from "views/TopUp/CreateTopUp.js";
import DeleteTopUp from "views/TopUp/DeleteTopUp.js";
import PrintTopUpInvoice from "views/TopUp/PrintTopUpInvoice.js";
import UploadPaymentReceipt from "views/TopUp/UploadPaymentReceipt.js";
import RemovePaymentReceipt from "views/TopUp/RemovePaymentReceipt.js";
import TopUpDetail from "views/TopUp/TopUpDetail.js";

import { token } from "functions/Helper";

const topUp = () => {
  let views = [];
  if (
    token["creditmonitoring.top_ups.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/TopUps/Pending",
        name: "Pending",
        mini: "PE",
        component: PendingTopUpList,
        layout: "/admin"
      },
      {
        path: "/TopUps/Approved",
        name: "Approved",
        mini: "AP",
        component: ApprovedTopUpList,
        layout: "/admin"
      },
      {
        path: "/TopUps/Rejected",
        name: "Rejected",
        mini: "RE",
        component: RejectedTopUpList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Top Ups",
        icon: AddIcon,
        state: "topUpCollapse",
        views: views
      }
    ];
  return [];
};

const topUpHidden = () => {
  let views = [];
  if (
    token["creditmonitoring.top_ups.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/TopUps/Create",
        name: "Create Top Up",
        component: CreateTopUp,
        layout: "/admin"
      },
      {
        path: "/TopUps/UploadPaymentReceipt",
        name: "Upload Payment Receipt",
        component: UploadPaymentReceipt,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/TopUps/RemovePaymentReceipt",
        name: "Remove Payment Receipt",
        component: RemovePaymentReceipt,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/TopUps/Delete",
        name: "Delete Top Up",
        component: DeleteTopUp,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (
    token["creditmonitoring.top_ups.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/TopUps/PrintInvoice",
        name: "Print Invoice",
        component: PrintTopUpInvoice,
        layout: "/empty",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/TopUps/Detail",
        name: "Top Up Detail",
        component: TopUpDetail,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "TopUp Hidden",
        state: "topUpHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { topUp, topUpHidden };
