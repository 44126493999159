import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Check from "@material-ui/icons/Check";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { bookingApi } from "functions/apis";
import GridContainer from "components/Grid/GridContainer";

import styles from "assets/jss/material-dashboard-pro-react/views/Booking/passengerList";

const useStyles = makeStyles(styles);

export default function CancellationAvailablePassengerList(props) {
  const { bookingCancellation, reload, setReload } = props;
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    searchString: null
  });
  const [loading, setLoading] = React.useState(false);

  const [
    selectedBookingDetailIDs,
    setSelectedBookingDetailIDs
  ] = React.useState([]);

  const handlePassengerSelectedChanged = (checked, id) => {
    let tmpSelectedBookingDetailsIDs = selectedBookingDetailIDs.map(r => r);
    if (checked)
      tmpSelectedBookingDetailsIDs = tmpSelectedBookingDetailsIDs.concat([id]);
    else
      tmpSelectedBookingDetailsIDs = tmpSelectedBookingDetailsIDs.filter(
        v => v != id
      );
    setSelectedBookingDetailIDs(tmpSelectedBookingDetailsIDs);
  };

  useEffect(() => {
    setLoading(true);
    bookingApi
      .get(
        `/BookingCancellations/${bookingCancellation.id}/AvailablePassengerList`,
        {
          signal: abortSignal.current.signal,
          params: {
            filter: filter,
            pagination: pagination
          }
        }
      )
      .then(data => {
        if (bookingCancellation.status !== 0) setData(data.records);
        else
          setData(
            data.records.map(record => {
              return {
                ...record,
                actions: (
                  <div className="actions-right">
                    <Checkbox
                      checked={selectedBookingDetailIDs.includes(record.id)}
                      onChange={e =>
                        handlePassengerSelectedChanged(
                          e.target.checked,
                          record.id
                        )
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  </div>
                )
              };
            })
          );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter, reload, selectedBookingDetailIDs]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .post(
        `/BookingCancellations/${bookingCancellation.id}/Details`,
        {
          bookingDetailIDs: selectedBookingDetailIDs
        },
        {
          signal: abortSignal.current.signal
        }
      )
      .then(() => setReload({}))
      .catch(error => console.log(error))
      .finally(() => setDisabled(false));
  };

  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <Assignment />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>AVAILABLE FOR CANCELLATION</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText="Search"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: handleSearchStringChanged,
                value: filter.searchString === null ? "" : filter.searchString
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <form onSubmit={handleSubmit}>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                ADD FOR CANCELLATION
              </Button>
            </form>
          </GridItem>
        </GridContainer>
        <ReactTable
          manual
          pages={pages}
          page={pagination.pageIndex - 1}
          pageSize={pagination.pageSize}
          onPageChange={page =>
            setPagination({
              pageIndex: page + 1,
              pageSize: pagination.pageSize
            })
          }
          onPageSizeChange={(pageSize, page) => {
            setPagination({ pageSize: pageSize, pageIndex: page + 1 });
          }}
          loading={loading}
          data={data}
          filterable={false}
          columns={[
            {
              id: "no",
              Header: "No",
              accessor: d => d.identification.no
            },
            {
              id: "fullName",
              Header: "Full Name",
              accessor: d => d.identification.fullName
            },
            {
              id: "departureVoucher",
              Header: "Departure Voucher",
              accessor: d =>
                d.departureVoucherCode === null
                  ? "NO UTILISED VOUCHER"
                  : d.departureVoucherCode.id
            },
            {
              id: "returnVoucher",
              Header: "Return Voucher",
              accessor: d =>
                d.returnVoucherCode === null
                  ? "NO UTILISED VOUCHER"
                  : d.returnVoucherCode.id
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false
            }
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
        />
      </CardBody>
    </Card>
  );
}

CancellationAvailablePassengerList.propTypes = {
  bookingCancellation: PropTypes.object,
  reload: PropTypes.object,
  setReload: PropTypes.func
};
