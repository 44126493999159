import React, { useEffect, useRef } from "react";
//import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { agentApi, creditMonitoringApi } from "functions/apis";

import { token } from "functions/Helper";

import BalanceHistoryList from "./BalanceHistoryList";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function MyAgent() {
  const abortSignal = useRef(new AbortController());

  const [agent, setAgent] = React.useState(null);
  const [agentCredit, setAgentCredit] = React.useState(null);

  useEffect(() => {
    agentApi
      .get("/Agents", {
        signal: abortSignal.current.signal
      })
      .then(data => setAgent(data))
      .catch(error => console.log(error));

    if (
      token["creditmonitoring.agents.read"] === "allowed" ||
      token.is_admin === "True"
    )
      creditMonitoringApi
        .get("/Agents", {
          signal: abortSignal.current.signal
        })
        .then(data => setAgentCredit(data))
        .catch(error => console.log(error));

    return () => abortSignal.current.abort();
  }, []);

  const classes = useStyles();
  return (
    agent !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>PROFILE</h4>
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Code"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: agent.code
                }}
              />

              <CustomInput
                labelText="Name"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: agent.name
                }}
              />
              <CustomInput
                labelText="Email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: agent.email === null ? "" : agent.email
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Person"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        agent.contactPerson === null ? "" : agent.contactPerson
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Company Address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        agent.companyAddress === null
                          ? ""
                          : agent.companyAddress
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Number (Office)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        agent.officeContactNumber === null
                          ? ""
                          : agent.officeContactNumber
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Number (Mobile)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        agent.mobileContactNumber === null
                          ? ""
                          : agent.mobileContactNumber
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {agentCredit !== null && (
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>CREDIT INFORMATION</h4>
              </CardHeader>
              <CardBody>
                {/*
                            <Link to={"/admin/EditCreditInformation"}>
                            <Button color="rose" type="button" value="Create">Edit</Button>
                            </Link>
                            */}
                <GridContainer>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Billing Currency"
                      disabled
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          agentCredit.billingCurrency === null
                            ? ""
                            : agentCredit.billingCurrency.code
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Current Balance"
                      disabled
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: agentCredit.currentBalance.toLocaleString()
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Credit Limit Balance"
                      disabled
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: agentCredit.creditLimitBalance.toLocaleString()
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Balance Alert Threshold"
                      disabled
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: agentCredit.balanceAlertThreshold.toLocaleString()
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          )}
          {(token["creditmonitoring.agents.balance_histories"] === "allowed" ||
            token.is_admin === "True") &&
            agentCredit !== null && (
              <BalanceHistoryList
                billingCurrency={agentCredit.billingCurrency}
              />
            )}
        </GridItem>
      </GridContainer>
    )
  );
}
