import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import MailOutline from "@material-ui/icons/MailOutline";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "components/CustomButtons/Button.js";
import Datetime from "react-datetime";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { formatDateTime, getTimezoneLabel } from "functions/Helper";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import moment from "moment";
import { useEffect } from "react";
import { bookingApi } from "functions/apis";
import { useRef } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { coreApi } from "functions/apis";
import { token } from "functions/Helper";
import { masterApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function EditViewBooking(props) {
  const { history, id } = props;

  const [
    availableDepartureSectorList,
    setAvailableDepartureSectorList
  ] = React.useState([]);
  const abortSignal = useRef(new AbortController());
  const classes = useStyles();
  const [departureRouteList, setDepartureRouteList] = React.useState([]);
  const [departureScheduleList, setDepartureScheduleList] = React.useState([]);
  const [returnRouteList, setReturnRouteList] = React.useState([]);
  const [returnScheduleList, setReturnScheduleList] = React.useState([]);
  const [redirect, setRedirect] = React.useState(false);
  const [returnSectorID, setReturnSectorID] = React.useState(null);
  const [departureSectorID, setDepartureSectorID] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const [isFirstDepart, setIsFirstDepart] = React.useState(true);
  const [isFirstReturn, setIsFirstReturn] = React.useState(true);

  let defaultCoreApiTrip = {
    date: null,
    routeID: null,
    id: null,
    time: null,
    gateOpen: null,
    gateClose: null
  };
  const [booking, setBooking] = React.useState(null);
  const [state, setState] = React.useState({
    isRoundTrip: false,
    isReturnTripOpen: false,
    departureCoreApiTrip: {
      ...defaultCoreApiTrip
    },
    returnCoreApiTrip: null
  });

  useEffect(() => {
    bookingApi
      .get("/Bookings/" + id, {
        signal: abortSignal.current.signal
      })
      .then(data => {
        setBooking(data);
        setState({
          isRoundTrip: data.isRoundTrip,
          isReturnTripOpen: data.isReturnTripOpen,
          departureCoreApiTrip: {
            ...data.departureCoreApiTrip,
            date: new Date(data.departureCoreApiTrip.date + "Z"),
            routeID: data.departureCoreApiTrip.route.id,
            route: undefined
          },
          returnCoreApiTrip:
            data.returnCoreApiTrip === null
              ? null
              : {
                  ...data.returnCoreApiTrip,
                  date: new Date(data.returnCoreApiTrip.date + "Z"),
                  routeID: data.returnCoreApiTrip.route.id,
                  route: undefined
                }
        });
        let departureSectorID = data.departureCoreApiTrip.route.sector.id;
        bookingApi
          .get("/Sectors/Available", {
            signal: abortSignal.current.signal
          })
          .then(data => {
            setAvailableDepartureSectorList(data.records);
            setDepartureSectorID(departureSectorID);
          })
          .catch(error => console.log(error));
      })
      .catch(error => console.log(error));

    return () => abortSignal.current.abort();
  }, []);

  useEffect(() => {
    if (departureSectorID !== null) {
      masterApi
        .get("/Routes", {
          signal: abortSignal.current.signal,
          params: {
            filter: {
              searchString: null,
              sectorID: departureSectorID,
              sort: 2
            },
            pagination: {
              pageIndex: 0,
              pageSize: 0
            }
          }
        })
        .then(data => {
          setDepartureRouteList(data.records);
          if (isFirstDepart) {
            updateScheduleList(
              state.departureCoreApiTrip.routeID,
              state.departureCoreApiTrip.date,
              data.records,
              setDepartureScheduleList
            );
            setIsFirstDepart(false);
          }
        })
        .catch(error => console.log(error));
      let selectedDepartureSector = availableDepartureSectorList.find(
        s => s.id === departureSectorID
      );
      setReturnSectorID(selectedDepartureSector.nextSector.id);
    } else {
      setDepartureRouteList([]);
      setReturnSectorID(null);
    }
  }, [departureSectorID]);

  useEffect(() => {
    if (returnSectorID != null) {
      masterApi
        .get("/Routes", {
          signal: abortSignal.current.signal,
          params: {
            filter: {
              searchString: null,
              sectorID: returnSectorID
            },
            pagination: {
              pageIndex: 0,
              pageSize: 0
            }
          }
        })
        .then(data => {
          setReturnRouteList(data.records);
          if (isFirstReturn) {
            updateScheduleList(
              state.returnCoreApiTrip.routeID,
              state.returnCoreApiTrip.date,
              data.records,
              setReturnScheduleList
            );
            setIsFirstReturn(false);
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      setReturnRouteList([]);
    }
  }, [returnSectorID]);

  const getReturnCoreApiTrip = (isRoundTrip, isReturnTripOpen) => {
    return isRoundTrip && !isReturnTripOpen ? { ...defaultCoreApiTrip } : null;
  };

  const handleIsRoundTripChanged = e =>
    setState({
      ...state,
      isRoundTrip: e.target.checked,
      returnCoreApiTrip: getReturnCoreApiTrip(
        e.target.checked,
        state.isReturnTripOpen
      )
    });

  const handleIsReturnTripOpenChanged = e =>
    setState({
      ...state,
      isReturnTripOpen: e.target.checked,
      returnCoreApiTrip: getReturnCoreApiTrip(
        state.isRoundTrip,
        e.target.checked
      )
    });

  const handleDepartureDateChanged = e => {
    let newDate = e === "" ? null : e;
    setState({
      ...state,
      departureCoreApiTrip: {
        ...state.departureCoreApiTrip,
        date: e === "" ? null : e,
        id: null,
        time: null,
        gateOpen: null,
        gateClose: null
      }
    });
    updateScheduleList(
      state.departureCoreApiTrip.routeID,
      newDate,
      departureRouteList,
      setDepartureScheduleList
    );
  };

  const handleDepartureRouteIDChanged = e => {
    let newRouteID = e.target.value === "" ? null : e.target.value;
    setState({
      ...state,
      departureCoreApiTrip: {
        ...state.departureCoreApiTrip,
        routeID: newRouteID,
        id: null,
        time: null,
        gateOpen: null,
        gateClose: null
      }
    });
    updateScheduleList(
      newRouteID,
      state.departureCoreApiTrip.date,
      departureRouteList,
      setDepartureScheduleList
    );
  };

  const handleDepartureTripChanged = e => {
    let selectedTripTime = e.target.value === "" ? null : e.target.value;
    if (selectedTripTime !== null) {
      let selectedTrip = departureScheduleList.find(
        schedule => schedule.tripTime === selectedTripTime
      );
      setState({
        ...state,
        departureCoreApiTrip: {
          ...state.departureCoreApiTrip,
          id: selectedTrip.tripID,
          gateOpen: selectedTrip.gateOpen,
          time: selectedTrip.tripTime,
          gateClose: selectedTrip.gateClose
        }
      });
    } else {
      setState({
        ...state,
        departureCoreApiTrip: {
          ...state.departureCoreApiTrip,
          id: null,
          gateOpen: null,
          time: null,
          gateClose: null
        }
      });
    }
  };

  const handleReturnDateChanged = e => {
    let newDate = e === "" ? null : e;
    setState({
      ...state,
      returnCoreApiTrip: {
        ...state.returnCoreApiTrip,
        date: e === "" ? null : e,
        id: null,
        time: null,
        gateOpen: null,
        gateClose: null
      }
    });
    updateScheduleList(
      state.returnCoreApiTrip.routeID,
      newDate,
      returnRouteList,
      setReturnScheduleList
    );
  };

  const handleReturnTripChanged = e => {
    let selectedTripTime = e.target.value === "" ? null : e.target.value;
    if (selectedTripTime !== null) {
      let selectedTrip = returnScheduleList.find(
        schedule => schedule.tripTime === selectedTripTime
      );
      setState({
        ...state,
        returnCoreApiTrip: {
          ...state.returnCoreApiTrip,
          id: selectedTrip.tripID,
          gateOpen: selectedTrip.gateOpen,
          time: selectedTrip.tripTime,
          gateClose: selectedTrip.gateClose
        }
      });
    } else {
      setState({
        ...state,
        returnCoreApiTrip: {
          ...state.returnCoreApiTrip,
          id: null,
          gateOpen: null,
          time: null,
          gateClose: null
        }
      });
    }
  };

  const handleReturnRouteIDChanged = e => {
    let newRouteID = e.target.value === "" ? null : e.target.value;
    setState({
      ...state,
      returnCoreApiTrip: {
        ...state.returnCoreApiTrip,
        routeID: newRouteID,
        id: null,
        time: null,
        gateOpen: null,
        gateClose: null
      }
    });
    updateScheduleList(
      newRouteID,
      state.returnCoreApiTrip.date,
      returnRouteList,
      setReturnScheduleList
    );
  };

  const updateScheduleList = (routeID, date, routeList, setScheduleList) => {
    if (routeID !== null && date !== null && routeList) {
      let selectedRoute = routeList.find(r => r.id === routeID);
      if (selectedRoute) {
        let ports = selectedRoute.code.split(" - ");
        let embarkationPort = ports[0];
        let destinationPort = ports[1];
        if (embarkationPort === "TMFT") embarkationPort = "TMF";
        if (destinationPort === "TMFT") destinationPort = "TMF";
        bookingApi
          .get(`/Ports/ScheduleTimezone/${selectedRoute.embarkationPort.id}`)
          .then(data => {
            let scheduleTimezone = data;
            coreApi
              .get("/Trips/GetTripWeb", {
                signal: abortSignal.current.signal,
                params: {
                  embarkation: embarkationPort,
                  destination: destinationPort,
                  tripDate: moment(date).format("yyyyMMDD"),
                  paxCount: 1,
                  agentID: token.aid
                }
              })
              .then(data =>
                setScheduleList(
                  data.data.map(record => {
                    let status =
                      record.usedSeat > 0 ? (
                        <p className={classes.green}>AVAILABLE</p>
                      ) : (
                        <p className={classes.red}>NO SEAT LEFT</p>
                      );
                    if (record.usedSeat <= 10)
                      status = (
                        <p
                          className={classes.red}
                        >{`${record.usedSeat} seat(s) left!`}</p>
                      );
                    else if (record.usedSeat <= 50)
                      status = <p className={classes.orange}>SELLING FAST</p>;
                    return {
                      ...record,
                      tripTime: record.departureTime.replace(":", ""),
                      status: status,
                      timezoneLabel: getTimezoneLabel(scheduleTimezone)
                    };
                  })
                )
              )
              .catch(error => {
                console.log(error);
                setScheduleList([]);
              });
          })
          .catch(error => {
            console.log(error);
            setScheduleList([]);
          });
      }
    } else {
      setScheduleList([]);
    }
  };

  const handleDepartureSectorIDChanged = e => {
    setDepartureSectorID(e.target.value);
    let tmpReturnCoreApiTrip = getReturnCoreApiTrip(
      state.isRoundTrip,
      state.isReturnTripOpen
    );
    setState({
      ...state,
      departureCoreApiTrip: {
        ...defaultCoreApiTrip,
        date: state.departureCoreApiTrip.date
      },
      returnCoreApiTrip: {
        ...tmpReturnCoreApiTrip,
        date: state.returnCoreApiTrip?.date
      }
    });
  };

  const onUpdate = () => {
    const temp = { ...state };
    let payload = {
      IsRoundTrip: temp.isRoundTrip,
      DepartureCoreApiTrip: temp.departureCoreApiTrip,
      IsReturnTripOpen: temp.isReturnTripOpen,
      ReturnCoreApiTrip: temp.returnCoreApiTrip
    };
    setDisabled(true);

    bookingApi
      .put(`/Bookings/${id}`, payload)
      .then(() => {
        setRedirect(true);
      })
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  return redirect ? (
    <Redirect to={"/admin/Booking/DetailPassenger/" + id} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>BOOKING DETAILS</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => history.goBack()} value="Back">
              BACK
            </Button>
            <CustomInput
              labelText="Booking No"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: booking === null ? "" : booking.no
              }}
            />
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="selDepartureSector"
                className={classes.selectLabel}
              >
                <b>Departure Sector</b>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={departureSectorID === null ? "" : departureSectorID}
                onChange={handleDepartureSectorIDChanged}
                inputProps={{
                  name: "selDepartureSector",
                  id: "selDepartureSector"
                }}
              >
                {availableDepartureSectorList.map(record => {
                  return (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={record.id}
                      key={record.id}
                    >
                      {record.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.isRoundTrip}
                  onChange={handleIsRoundTripChanged}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot
              }}
              label="Round Trip"
            />
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <InputLabel className={classes.selectLabel}>
                  <b>Departure Date</b>
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    value={state.departureCoreApiTrip.date}
                    onChange={handleDepartureDateChanged}
                    dateFormat={"dddd, MMMM Do YYYY"}
                    timeFormat={false}
                    utc={true}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "Departure Date"
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selDepartureRoute"
                    className={classes.selectLabel}
                  >
                    <b>Departure Route</b>
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={
                      state.departureCoreApiTrip.routeID === null
                        ? ""
                        : state.departureCoreApiTrip.routeID
                    }
                    onChange={handleDepartureRouteIDChanged}
                    inputProps={{
                      name: "selDepartureRoute",
                      id: "selDepartureRoute"
                    }}
                  >
                    {departureRouteList.map(record => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={record.id}
                          key={record.id}
                        >
                          {record.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selDepartureTrip"
                    className={classes.selectLabel}
                  >
                    <b>{`Departure Time`}</b>
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={state.departureCoreApiTrip.time ?? ""}
                    onChange={handleDepartureTripChanged}
                    inputProps={{
                      name: "selDepartureTrip",
                      id: "selDepartureTrip"
                    }}
                  >
                    {departureScheduleList.map(record => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={record.tripTime}
                          key={record.tripTime}
                        >
                          <p>
                            <b>{`${record.tripTime.substring(
                              0,
                              2
                            )}:${record.tripTime.substring(2, 4)}`}</b>{" "}
                            {record.timezoneLabel}
                          </p>
                          <b>{record.status}</b>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            {state.isRoundTrip && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.isReturnTripOpen}
                      onChange={handleIsReturnTripOpenChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Open Return Trip"
                />
                <br />
                {!state.isReturnTripOpen && state.returnCoreApiTrip && (
                  <>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <InputLabel className={classes.selectLabel}>
                          <b>Return Date</b>
                        </InputLabel>
                        <FormControl fullWidth>
                          <Datetime
                            value={state.returnCoreApiTrip?.date}
                            onChange={handleReturnDateChanged}
                            dateFormat={"dddd, MMMM Do YYYY"}
                            timeFormat={false}
                            utc={true}
                            closeOnSelect={true}
                            inputProps={{
                              placeholder: "Return Date"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="selReturnRoute"
                            className={classes.selectLabel}
                          >
                            <b>Return Route</b>
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={
                              state.returnCoreApiTrip?.routeID === null
                                ? ""
                                : state.returnCoreApiTrip.routeID
                            }
                            onChange={handleReturnRouteIDChanged}
                            inputProps={{
                              name: "selReturnRoute",
                              id: "selReturnRoute"
                            }}
                          >
                            {returnRouteList.map(record => {
                              return (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={record.id}
                                  key={record.id}
                                >
                                  {record.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="selReturnTrip"
                            className={classes.selectLabel}
                          >
                            <b>{`Return Time`}</b>
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={
                              state.returnCoreApiTrip?.time === null
                                ? ""
                                : state.returnCoreApiTrip.time
                            }
                            onChange={handleReturnTripChanged}
                            inputProps={{
                              name: "selReturnTrip",
                              id: "selReturnTrip"
                            }}
                          >
                            {returnScheduleList.map(record => {
                              return (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={record.tripTime}
                                  key={record.tripTime}
                                >
                                  <p>
                                    <b>{`${record.tripTime.substring(
                                      0,
                                      2
                                    )}:${record.tripTime.substring(
                                      2,
                                      4
                                    )}`}</b>{" "}
                                    {record.timezoneLabel}
                                  </p>
                                  <b>{record.status}</b>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </>
                )}
              </>
            )}
            <CustomInput
              labelText="Transaction Time"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value:
                  booking === null
                    ? ""
                    : formatDateTime(new Date(booking.transactionTime + "Z"))
              }}
            />
            <Button
              onClick={() => onUpdate()}
              disabled={disabled}
              color="rose"
              type="submit"
              value="Submit"
            >
              Update
            </Button>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

EditViewBooking.propTypes = {
  history: PropTypes.object,
  id: PropTypes.string
};
