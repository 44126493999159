import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import ViewBookingType from "./ViewBookingType";
import { bookingApi } from "functions/apis";

export default function BookingTypeDetail() {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  let { id } = useParams();

  const [bookingType, setBookingType] = React.useState(null);
  useEffect(() => {
    bookingApi
      .get("/BookingTypes/" + id, {
        signal: abortSignal.current.signal
      })
      .then(data => setBookingType(data))
      .catch(error => console.log(error));
  }, [id]);

  return bookingType !== null && <ViewBookingType bookingType={bookingType} />;
}
