import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { agentApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function ChangePassword(props) {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const handleCurrentPasswordChanged = e =>
    setState({
      ...state,
      currentPassword: e.target.value === "" ? null : e.target.value
    });

  const handleNewPasswordChanged = e =>
    setState({
      ...state,
      newPassword: e.target.value === "" ? null : e.target.value
    });

  const handleConfirmPasswordChanged = e =>
    setState({
      ...state,
      confirmPassword: e.target.value === "" ? null : e.target.value
    });

  const [state, setState] = React.useState({
    currentPassword: null,
    newPassword: null,
    confirmPassword: null
  });

  const handleMouseDownPassword = e => e.preventDefault();

  const handleShowCurrentPasswordClicked = () =>
    setShowCurrentPassword(!showCurrentPassword);
  const handleShowNewPasswordClicked = () =>
    setShowNewPassword(!showNewPassword);
  const handleShowConfirmPasswordClicked = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const classes = useStyles();

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    if (state.newPassword === state.confirmPassword) {
      setDisabled(true);
      agentApi
        .post("/Profile/ChangePassword", state, {
          signal: abortSignal.current.signal
        })
        .then(() => setRedirect(true))
        .catch(error => {
          console.log(error);
          setDisabled(false);
        });
    } else {
      alert("Confirm Password is not same with New Password.");
    }
  };
  return redirect ? (
    <Redirect to={"/admin/MyUser"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>CHANGE PSASWORD</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Current Password"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleCurrentPasswordChanged,
                  value:
                    state.currentPassword === null ? "" : state.currentPassword,
                  type: showCurrentPassword ? "text" : "password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowCurrentPasswordClicked}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showCurrentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <CustomInput
                labelText="New Password"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleNewPasswordChanged,
                  value: state.newPassword === null ? "" : state.newPassword,
                  type: showNewPassword ? "text" : "password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowNewPasswordClicked}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <CustomInput
                labelText="Confirm Password"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleConfirmPasswordChanged,
                  value:
                    state.confirmPassword === null ? "" : state.confirmPassword,
                  type: showConfirmPassword ? "text" : "password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowConfirmPasswordClicked}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

ChangePassword.propTypes = {
  history: PropTypes.object
};
