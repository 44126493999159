import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import { bookingApi } from "functions/apis";
import BookingDialog from "components/BookingDialog/BookingDialog";

const useStyles = makeStyles(styles);

export default function CreateBookingCancellation(props) {
  const abortSignal = useRef(new AbortController());
  const [openModal, setOpenModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState("");

  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const [state, setState] = React.useState({
    reason: null,
    bookingID: null
  });

  const handleReasonChanged = e =>
    setState({
      ...state,
      reason: e.target.value === "" ? null : e.target.value
    });

  const handleBookingIDChanged = e => {
    setSelectedBooking(e.no);
    setOpenModal(false);
    setState({
      ...state,
      bookingID: e === null ? null : e.id
    });
  };
  const [disabled, setDisabled] = React.useState(false);
  const [redirection, setRedirection] = React.useState({
    redirect: false,
    id: null
  });
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .post("/BookingCancellations", state, {
        signal: abortSignal.current.signal
      })
      .then(data =>
        setRedirection({
          redirect: true,
          id: data
        })
      )
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };
  const classes = useStyles();
  return redirection.redirect ? (
    <Redirect to={`/admin/BookingCancellation/Detail/${redirection.id}`} />
  ) : (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                CREATE BOOKING CANCELLATION
              </h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <form onSubmit={handleSubmit}>
                <GridItem xs={12} sm={8} md={8}>
                  <TextField
                    className={classes.option}
                    value={selectedBooking}
                    label={<b>BOOKING</b>}
                    variant="outlined"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                    fullWidth={true}
                  />
                </GridItem>
                <BookingDialog
                  onSelect={handleBookingIDChanged}
                  openModal={openModal}
                  setOpenModal={val => {
                    setOpenModal(val);
                  }}
                  url={"/BookingCancellations/AvailableBookingForCancel"}
                />
                <CustomInput
                  labelText="Reason"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleReasonChanged,
                    value: state.reason === null ? "" : state.reason
                  }}
                />
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

CreateBookingCancellation.propTypes = {
  history: PropTypes.object
};
