import React, { useEffect, useRef } from "react";
import Datetime from "react-datetime";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Check from "@material-ui/icons/Check";
// core components
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { orderApi, creditMonitoringApi } from "functions/apis";
import { formatDateTime, formatCurrency } from "functions/Helper";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function VoucherTypeList() {
  const abortSignal = useRef(new AbortController());
  const [agentCredit, setAgentCredit] = React.useState(null);
  useEffect(() => {
    creditMonitoringApi
      .get("/Agents", {
        signal: abortSignal.current.signal
      })
      .then(data => setAgentCredit(data))
      .catch(error => console.log(error));
    return () => abortSignal.current.abort();
  }, []);

  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    searchString: null
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    orderApi
      .get("/AgentVoucherTypes/WithBillingPrice", {
        signal: abortSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <Link
                    to={"/admin/VoucherTypeDetail/" + record.voucherType.id}
                  >
                    <Button color="info" className="like">
                      <VisibilityIcon /> View
                    </Button>
                  </Link>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const handleActiveDateChanged = e =>
    setFilter({
      ...filter,
      activeDate: e === "" ? null : e
    });

  const handleCurrentActiveChanged = e =>
    setFilter({
      ...filter,
      currentActive: e.target.checked
    });

  const classes = useStyles();
  return (
    agentCredit !== null && (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>VOUCHER TYPE</h4>
            </CardHeader>
            <CardBody>
              <GridItem xs={3}>
                <CustomInput
                  labelText="Search"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleSearchStringChanged,
                    value:
                      filter.searchString === null ? "" : filter.searchString
                  }}
                />
              </GridItem>
              <GridItem xs={3}>
                <FormControl fullWidth>
                  <Datetime
                    value={filter.activeDate}
                    onChange={handleActiveDateChanged}
                    timeFormat={false}
                    inputProps={{
                      placeholder: "Active Date"
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.currentActive}
                      onChange={handleCurrentActiveChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Current Active"
                />
              </GridItem>
              <ReactTable
                manual
                pages={pages}
                page={pagination.pageIndex - 1}
                pageSize={pagination.pageSize}
                onPageChange={page =>
                  setPagination({
                    pageIndex: page + 1,
                    pageSize: pagination.pageSize
                  })
                }
                onPageSizeChange={(pageSize, page) => {
                  setPagination({ pageSize: pageSize, pageIndex: page + 1 });
                }}
                loading={loading}
                data={data}
                filterable={false}
                columns={[
                  {
                    id: "code",
                    Header: "Code",
                    accessor: d => d.voucherType.code
                  },
                  {
                    id: "name",
                    Header: "Name",
                    accessor: d => d.voucherType.name
                  },
                  {
                    id: "billingPrice",
                    Header: "Billing Price",
                    accessor: d =>
                      formatCurrency(
                        agentCredit.billingCurrency === null
                          ? "XXX"
                          : agentCredit.billingCurrency.code,
                        d.billingPrice
                      )
                  },
                  {
                    id: "recommendedRetailPrice",
                    Header: "Recommended Retail Price",
                    accessor: d =>
                      formatCurrency(
                        agentCredit.billingCurrency === null
                          ? "XXX"
                          : agentCredit.billingCurrency.code,
                        d.recommendedRetailPrice
                      )
                  },
                  {
                    id: "effectiveDate",
                    Header: "Effective Date",
                    accessor: d =>
                      formatDateTime(new Date(d.effectiveDate + "Z"))
                  },
                  {
                    id: "expiryDate",
                    Header: "Expiry Date",
                    accessor: d =>
                      d.expiryDate === null
                        ? ""
                        : formatDateTime(new Date(d.expiryDate + "Z"))
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}
