import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { orderApi } from "functions/apis";

import { token } from "functions/Helper";

export default function ViewOrder(props) {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const { id } = props;

  const [order, setOrder] = React.useState(null);
  const [orderPrice, setOrderPrice] = React.useState(null);
  useEffect(() => {
    orderApi
      .get("/Orders/" + id, {
        signal: abortSignal.current.signal
      })
      .then(data => setOrder(data))
      .catch(error => console.log(error));

    if (
      token["order.orders.read_price"] === "allowed" ||
      token.is_admin === "True"
    )
      orderApi
        .get("/Orders/" + id + "/Price", {
          signal: abortSignal.current.signal
        })
        .then(data => setOrderPrice(data))
        .catch(error => console.log(error));
  }, [id]);

  return (
    order !== null && (
      <>
        <CustomInput
          disabled
          labelText="No"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: order.no
          }}
        />
        <CustomInput
          disabled
          labelText="Voucher Type"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: order.voucherType.code
          }}
        />
        <CustomInput
          disabled
          labelText="Quantity"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: order.quantity.toLocaleString()
          }}
        />
        {orderPrice !== null && (
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                disabled
                labelText="Currency"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value:
                    orderPrice.currency === null ? "" : orderPrice.currency.code
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                disabled
                labelText="Unit Price"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: orderPrice.unitPrice.toLocaleString()
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                disabled
                labelText="Total"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: orderPrice.total.toLocaleString()
                }}
              />
            </GridItem>
          </GridContainer>
        )}
      </>
    )
  );
}

ViewOrder.propTypes = {
  id: PropTypes.string
};
