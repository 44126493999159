export function GetBookingStatuses() {
  return [
    {
      key: 0,
      description: "Pending"
    },
    {
      key: 1,
      description: "Submitted"
    }
  ];
}

export function GetBookingStatusDescription(value) {
  let bookingStatus = GetBookingStatuses().find(item => item.key === value);
  return bookingStatus.description;
}
