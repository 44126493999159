export function GetFeeCalculationTypes() {
  return [
    {
      key: 0,
      description: "Fixed"
    },
    {
      key: 1,
      description: "By Percentage"
    }
  ];
}

export function GetFeeCalculationTypeDescription(value) {
  let feeCalculationType = GetFeeCalculationTypes().find(
    item => item.key === value
  );
  return feeCalculationType.description;
}
