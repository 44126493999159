import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import { GetBookingStatusDescription } from "enums/BookingStatus";

import { bookingApi, creditMonitoringApi } from "functions/apis";
import { token } from "functions/Helper";
import ViewBooking from "./View";
import PassengerList from "./PassengerList";
import ViewPassengerList from "./ViewPassengerList";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);

export default function Detail(props) {
  const abortSignal = useRef(new AbortController());
  useEffect(() => {
    return () => abortSignal.current.abort();
  }, []);

  const { id } = useParams();
  const [agentCredit, setAgentCredit] = React.useState(null);
  const [booking, setBooking] = React.useState(null);

  const loadPricing =
    token["booking.bookings.read_with_pricing"] === "allowed" ||
    token.is_admin === "True";

  useEffect(() => {
    if (loadPricing) {
      bookingApi
        .get(`/Bookings/${id}/WithPricing`, {
          signal: abortSignal.current.signal
        })
        .then(data => setBooking(data))
        .catch(error => console.log(error));

      if (
        token["creditmonitoring.agents.read"] === "allowed" ||
        token.is_admin === "True"
      )
        creditMonitoringApi
          .get("/Agents", {
            signal: abortSignal.current.signal
          })
          .then(data => setAgentCredit(data))
          .catch(error => console.log(error));
    } else {
      bookingApi
        .get(`/Bookings/${id}`, {
          signal: abortSignal.current.signal
        })
        .then(data => setBooking(data))
        .catch(error => console.log(error));
    }
  }, [id]);

  const classes = useStyles();
  return (
    booking !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ViewBooking booking={booking} history={props.history} />
          {booking.status === 0 ? (
            <PassengerList
              booking={booking}
              currency={
                agentCredit !== null && agentCredit.billingCurrency !== null
                  ? agentCredit.billingCurrency.code
                  : ""
              }
            />
          ) : (
            <ViewPassengerList
              id={booking.id}
              currency={
                agentCredit !== null && agentCredit.billingCurrency !== null
                  ? agentCredit.billingCurrency.code
                  : ""
              }
            />
          )}
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>ACTIONS</h4>
            </CardHeader>
            <CardBody>
              <p>
                <i>
                  *Booking status is &apos;
                  <b>{GetBookingStatusDescription(booking.status)}</b>&apos;.
                </i>
              </p>
              {booking.status === 0 &&
                (token["booking.bookings.write"] === "allowed" ||
                  token.is_admin === "True") && (
                  <>
                    <Link to={"/admin/Booking/Delete/" + booking.id}>
                      <Button color="rose" value="Delete">
                        DELETE
                      </Button>
                    </Link>
                    <Link to={"/admin/Booking/Submit/" + id}>
                      <Button color="info" className="like">
                        SUBMIT
                      </Button>
                    </Link>
                  </>
                )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

Detail.propTypes = {
  history: PropTypes.object
};
