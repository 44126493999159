import React, { useEffect, useRef } from "react";
import axios from "axios";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Autocomplete from "@material-ui/lab/Autocomplete";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import DeleteIcon from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
import CropFreeIcon from "@material-ui/icons/CropFree";
import Edit from "@material-ui/icons/Edit";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { bookingApi, masterApi } from "functions/apis";
import { GetGenders } from "enums/Gender";
import { GetGenderDescription } from "enums/Gender";
import GridContainer from "components/Grid/GridContainer";
import moment from "moment";

import styles from "assets/jss/material-dashboard-pro-react/views/Booking/passengerList";
import { formatCurrency, token } from "functions/Helper";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function PassengerList(props) {
  const { booking, currency } = props;
  const abortSignal = useRef(new AbortController());
  const dateFormat = "DD/MM/YYYY";

  const loadPricing =
    token["booking.bookings.read_with_pricing"] === "allowed" ||
    token.is_admin === "True";

  const [countryList, setCountryList] = React.useState([]);

  useEffect(() => {
    masterApi
      .get("/Countries", {
        signal: abortSignal.current.signal,
        params: {
          filter: {
            searchString: null
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setCountryList(data.records))
      .catch(error => console.log(error));

    return () => abortSignal.current.abort();
  }, []);

  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    searchString: null
  });
  const [editId, setEditId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState({});
  const [removePassenger, setRemovePassenger] = React.useState(null);

  const onEdit = data => {
    const tempData = data.identification;
    setState({
      type: tempData.type,
      no: tempData.no,
      fullName: tempData.fullName.trim(),
      gender: tempData.gender,
      dateOfBirth: new Date(tempData.dateOfBirth + "Z"),
      placeOfBirth: tempData.placeOfBirth,
      issueDate: new Date(tempData.issueDate + "Z"),
      expiryDate: new Date(tempData.expiryDate + "Z"),
      nationalityID: tempData.nationality.id,
      issuanceCountryID: tempData.issuanceCountry.id
    });
    setAutocompleteCache({
      ...autocompleteCache,
      nationality: tempData.nationality,
      issuanceCountry: tempData.issuanceCountry
    });
    setEditId(data.id);
  };

  useEffect(() => {
    setLoading(true);
    bookingApi
      .get(
        `/Bookings/${booking.id}/Details` + (loadPricing ? "/WithPricing" : ""),
        {
          signal: abortSignal.current.signal,
          params: {
            filter: filter,
            pagination: pagination
          }
        }
      )
      .then(data => {
        if (booking.status !== 0) setData(data.records);
        else {
          setData(
            data.records.map(record => {
              return {
                ...record,
                actions:
                  booking.status === 0 ? (
                    <div className="actions-right ">
                      <Button
                        color="info"
                        className="remove"
                        onClick={() => onEdit(record)}
                      >
                        <Edit /> EDIT
                      </Button>
                      <Button
                        color="danger"
                        className="remove"
                        onClick={() => setRemovePassenger(record)}
                      >
                        <DeleteIcon /> DELETE
                      </Button>
                    </div>
                  ) : null,
                departureVoucher:
                  record.departureVoucherCode === null ? (
                    <Button
                      color="info"
                      className="remove"
                      onClick={() =>
                        setUtiliseVoucherInfo({
                          mode: "Departure",
                          utilise: true,
                          passenger: record
                        })
                      }
                    >
                      <CropFreeIcon /> Utilise Voucher
                    </Button>
                  ) : (
                    <div className="actions-right">
                      <Button
                        color="danger"
                        className="remove"
                        onClick={() =>
                          setRemoveVoucherInfo({
                            mode: "Departure",
                            remove: true,
                            passenger: record
                          })
                        }
                      >
                        <DeleteIcon /> Remove Voucher
                      </Button>
                      <p>{record.departureVoucherCode.id.toUpperCase()}</p>
                    </div>
                  ),
                returnVoucher: !booking.isRoundTrip ? (
                  ""
                ) : record.returnVoucherCode === null ? (
                  <Button
                    color="info"
                    className="remove"
                    onClick={() =>
                      setUtiliseVoucherInfo({
                        mode: "Return",
                        utilise: true,
                        passenger: record
                      })
                    }
                  >
                    <CropFreeIcon /> Utilise Voucher
                  </Button>
                ) : (
                  <div className="actions-right">
                    <Button
                      color="danger"
                      className="remove"
                      onClick={() =>
                        setRemoveVoucherInfo({
                          mode: "Return",
                          remove: true,
                          passenger: record
                        })
                      }
                    >
                      <DeleteIcon /> Remove Voucher
                    </Button>
                    <p>{record.returnVoucherCode.id.toUpperCase()}</p>
                  </div>
                )
              };
            })
          );
        }
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter, reload]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const [upload, setUpload] = React.useState({
    uploading: false,
    file: null
  });

  const handleUpload = e =>
    setUpload({
      ...upload,
      file: e.target.files[0]
    });

  const handleSubmitImportExcel = e => {
    e.preventDefault();
    setDisabled(true);
    let data = new FormData();
    data.append("file", upload.file);
    bookingApi
      .post(`Bookings/${booking.id}/Details/Import`, data, {
        signal: abortSignal.current.signal
      })
      .then(() => {
        setUpload({
          uploading: false,
          file: null
        });
        setReload({});
      })
      .catch(error => console.log(error))
      .finally(() => setDisabled(false));
  };

  let defaultState = {
    type: 0,
    no: null,
    fullName: null,
    gender: 0,
    dateOfBirth: null,
    placeOfBirth: null,
    issueDate: null,
    expiryDate: null,
    nationalityID: null,
    issuanceCountryID: null
  };

  let defaultAutoCompleteCache = {
    nationality: null,
    issuanceCountry: null
  };

  const [state, setState] = React.useState({
    ...defaultState
  });

  const [autocompleteCache, setAutocompleteCache] = React.useState({
    ...defaultAutoCompleteCache
  });

  const handleNoChanged = e => {
    let input = e.target.value.toUpperCase();
    input = input.replace(/[^a-z0-9]/gi, ""); //allow alphanumeric only
    setState({
      ...state,
      no: input === "" ? null : input
    });
  };

  const handleFullNameChanged = e => {
    let input = e.target.value.toUpperCase();
    input = input.replace(/[^a-z ]/gi, ""); //allow alphanumeric and space
    setState({
      ...state,
      fullName: input === "" ? null : input
    });
  };

  const handleGenderChanged = e =>
    setState({
      ...state,
      gender: e.target.value === "" ? null : e.target.value
    });

  const handleDateOfBirthChanged = e => {
    let newDate = e === "" ? null : e;
    setState({
      ...state,
      dateOfBirth: newDate
    });
  };

  const handleNationalityIDChanged = (e, v) => {
    setState({ ...state, nationalityID: v === null ? null : v.id });
    setAutocompleteCache({ ...autocompleteCache, nationality: v });
  };

  const handleIssueDateChanged = e => {
    let newDate = e === "" ? null : e;
    setState({
      ...state,
      issueDate: newDate
    });
  };

  const handleExpiryDateChanged = e => {
    let newDate = e === "" ? null : e;
    setState({
      ...state,
      expiryDate: newDate
    });
  };

  const handleIssuanceCountryIDChanged = (e, v) => {
    setState({ ...state, issuanceCountryID: v === null ? null : v.id });
    setAutocompleteCache({ ...autocompleteCache, issuanceCountry: v });
  };

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);

    if (editId) {
      bookingApi
        .put(
          `/Bookings/${booking.id}/Details/${editId}`,
          {
            identification: {
              ...state,
              fullName: state.fullName.trim()
            }
          },
          {
            signal: abortSignal.current.signal
          }
        )
        .then(() => {
          setReload({});
          setState({
            ...defaultState
          });
          setAutocompleteCache({
            ...defaultAutoCompleteCache
          });
          setEditId(null);
        })
        .catch(error => console.log(error))
        .finally(() => {
          setDisabled(false);
        });
    } else {
      bookingApi
        .post(
          `/Bookings/${booking.id}/Details`,
          {
            identification: {
              ...state,
              fullName: state.fullName.trim()
            }
          },
          {
            signal: abortSignal.current.signal
          }
        )
        .then(() => {
          setReload({});
          setState({
            ...defaultState
          });
          setAutocompleteCache({
            ...defaultAutoCompleteCache
          });
        })
        .catch(error => console.log(error))
        .finally(() => setDisabled(false));
    }
  };

  const handleDelete = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .delete(`/Bookings/${booking.id}/Details/${removePassenger.id}`, {
        signal: abortSignal.current.signal
      })
      .then(() => {
        setRemovePassenger(null);
        setReload({});
      })
      .catch(error => console.log(error))
      .finally(() => setDisabled(false));
  };

  const [utiliseVoucherInfo, setUtiliseVoucherInfo] = React.useState({
    mode: "Departure",
    utilise: false,
    passenger: null
  });

  const [removeVoucherInfo, setRemoveVoucherInfo] = React.useState({
    mode: "Departure",
    remove: false,
    passenger: null
  });
  const handleVoucherCodeIDChanged = e =>
    setVoucherCodeID(e.target.value === "" ? null : e.target.value);

  const handleUtiliseVoucher = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .post(
        `/Bookings/${booking.id}/Details/Add${utiliseVoucherInfo.mode}VoucherCode/${utiliseVoucherInfo.passenger.id}`,
        {
          voucherCodeID: voucherCodeID
        },
        {
          signal: abortSignal.current.signal
        }
      )
      .then(() => {
        setUtiliseVoucherInfo({
          ...utiliseVoucherInfo,
          utilise: false,
          passenger: null
        });
        setVoucherCodeID(null);
        setReload({});
      })
      .catch(error => console.log(error))
      .finally(() => setDisabled(false));
  };

  const handleRemoveVoucher = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .delete(
        `/Bookings/${booking.id}/Details/Remove${removeVoucherInfo.mode}VoucherCode/${removeVoucherInfo.passenger.id}`,
        {
          signal: abortSignal.current.signal
        }
      )
      .then(() => {
        setRemoveVoucherInfo({
          ...removeVoucherInfo,
          remove: false,
          passenger: null
        });
        setVoucherCodeID(null);
        setReload({});
      })
      .catch(error => console.log(error))
      .finally(() => setDisabled(false));
  };

  const [voucherCodeID, setVoucherCodeID] = React.useState(null);

  const [tableMode, setTableMode] = React.useState("passenger");

  const switchViewButton = (
    <Button
      fullWidth
      disabled={disabled}
      color="info"
      onClick={() => {
        if (tableMode === "passenger") setTableMode("voucher");
        else setTableMode("passenger");
      }}
    >
      {tableMode === "passenger"
        ? "CLICK TO UTILISE VOUCHERS"
        : "CLICK TO RETURN TO PAX INFO"}
    </Button>
  );

  const expiryDateWarning = () => {
    if (state.expiryDate === null) return null;
    let travelDate = new Date(booking.departureCoreApiTrip.date);
    if (booking.isRoundTrip && !booking.isReturnTripOpen)
      travelDate = new Date(booking.returnCoreApiTrip.date);
    travelDate.setMonth(travelDate.getMonth() + 6);

    let expiryDate = new Date(state.expiryDate);
    if (travelDate > expiryDate)
      return (
        <small className={classes.warning}>
          (Expiry Date is less than 6 months when travelling. Please make sure
          to renew the passport before travelling.)
        </small>
      );
    else return null;
  };

  const passengerColumns = [
    {
      id: "no",
      Header: "No",
      accessor: d => d.identification.no
    },
    {
      id: "fullName",
      Header: "Full Name",
      accessor: d => d.identification.fullName
    },
    {
      id: "gender",
      Header: "Gender",
      accessor: d => GetGenderDescription(d.identification.gender)
    },
    {
      id: "dateOfBirth",
      Header: "Date of Birth",
      accessor: d => moment(d.identification.dateOfBirth).format(dateFormat)
    },
    {
      id: "nationality",
      Header: "Nationality",
      accessor: d => d.identification.nationality.nationality
    },
    {
      id: "issuanceCountry",
      Header: "Issuance Country",
      accessor: d => d.identification.issuanceCountry.name
    },
    {
      id: "issueDate",
      Header: "Issue Date",
      accessor: d => moment(d.identification.issueDate).format(dateFormat)
    },
    {
      id: "expiryDate",
      Header: "Expiry Date",
      accessor: d => moment(d.identification.expiryDate).format(dateFormat)
    },
    {
      Header: "Actions",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ];

  const voucherColumns = [
    {
      id: "no",
      Header: "No",
      accessor: d => d.identification.no
    },
    {
      id: "fullName",
      Header: "Full Name",
      accessor: d => d.identification.fullName
    },
    {
      id: "type",
      Header: "Type",
      accessor: d => d.bookingType.name
    }
  ]
    .concat(
      loadPricing
        ? [
            {
              id: "total",
              Header: "Total",
              accessor: d => formatCurrency(currency, d.total)
            },
            {
              id: "totalVoucherAmount",
              Header: "Voucher Amount",
              accessor: d => formatCurrency(currency, d.totalVoucherAmount)
            },
            {
              id: "totalOutstandingAmount",
              Header: "Outstanding Amount",
              accessor: d => formatCurrency(currency, d.totalOutstandingAmount)
            }
          ]
        : []
    )
    .concat([
      {
        id: "departureVoucher",
        Header: "Departure Voucher",
        accessor: d => d.departureVoucher
      },
      {
        id: "returnVoucher",
        Header: "Return Voucher",
        accessor: d => d.returnVoucher
      }
    ]);
  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <Assignment />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>PASSENGERS</h4>
      </CardHeader>
      <CardBody>
        {upload.uploading && (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal
            }}
            open={upload.uploading}
            TransitionComponent={Transition}
            keepMounted
            onClose={() =>
              setUpload({
                uploading: false,
                file: null
              })
            }
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
          >
            <DialogTitle
              id="notice-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() =>
                  setUpload({
                    uploading: false,
                    file: null
                  })
                }
              >
                <Close className={classes.modalClose} />
              </Button>
              <h4 className={classes.modalTitle}>Upload your excel file.</h4>
            </DialogTitle>
            <DialogContent
              id="notice-modal-slide-description"
              className={classes.modalBody}
            >
              <form onSubmit={handleSubmitImportExcel}>
                <Button
                  color="info"
                  className="like"
                  onClick={() =>
                    axios
                      .get("/files/import-passenger-example.xlsx", {
                        responseType: "arraybuffer",
                        contentType:
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      })
                      .then(response => {
                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                          "download",
                          "import-passenger-example.xlsx"
                        );
                        document.body.appendChild(link);
                        link.click();
                      })
                  }
                >
                  Download Example
                </Button>
                <Button
                  color="info"
                  className="like"
                  onClick={() =>
                    axios
                      .get("/files/import-passenger-guidelines.xlsx", {
                        responseType: "arraybuffer",
                        contentType:
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      })
                      .then(response => {
                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                          "download",
                          "import-passenger-guidelines.xlsx"
                        );
                        document.body.appendChild(link);
                        link.click();
                      })
                  }
                >
                  Download Guidelines
                </Button>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleUpload,
                        type: "file",
                        accept: ".xls,.xlsx"
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
            </DialogContent>
          </Dialog>
        )}
        {utiliseVoucherInfo.utilise && (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal
            }}
            open={utiliseVoucherInfo.utilise}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setUtiliseVoucherInfo({
                ...utiliseVoucherInfo,
                utilise: false
              });
              setVoucherCodeID(null);
            }}
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
          >
            <DialogTitle
              id="notice-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => {
                  setUtiliseVoucherInfo({
                    ...utiliseVoucherInfo,
                    utilise: false
                  });
                  setVoucherCodeID(null);
                }}
              >
                <Close className={classes.modalClose} />
              </Button>
              <h4 className={classes.modalTitle}>
                {"Utilise Voucher | "}
                {utiliseVoucherInfo.mode === "Departure"
                  ? booking.departureCoreApiTrip.route.code
                  : booking.isReturnTripOpen
                  ? "Open Trip"
                  : booking.returnCoreApiTrip.route.code}
              </h4>
            </DialogTitle>
            <DialogContent
              id="notice-modal-slide-description"
              className={classes.modalBody}
            >
              <CustomInput
                labelText="No"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value:
                    utiliseVoucherInfo.passenger.identification.no === null
                      ? ""
                      : utiliseVoucherInfo.passenger.identification.no
                }}
              />
              <CustomInput
                labelText="FullName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value:
                    utiliseVoucherInfo.passenger.identification.fullName ===
                    null
                      ? ""
                      : utiliseVoucherInfo.passenger.identification.fullName
                }}
              />
              <CustomInput
                labelText={`${utiliseVoucherInfo.mode} Voucher`}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleVoucherCodeIDChanged,
                  value: state.voucherCodeID === null ? "" : state.voucherCodeID
                }}
              />
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                onClick={() => {
                  setUtiliseVoucherInfo({
                    ...utiliseVoucherInfo,
                    utilise: false
                  });
                  setVoucherCodeID(null);
                }}
                round
              >
                Cancel
              </Button>
              <Button
                disabled={disabled}
                onClick={handleUtiliseVoucher}
                color="info"
                className="remove"
                round
              >
                <CropFreeIcon /> Utilise
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {removeVoucherInfo.remove && (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal
            }}
            open={removeVoucherInfo.remove}
            TransitionComponent={Transition}
            keepMounted
            onClose={() =>
              setRemoveVoucherInfo({
                ...removeVoucherInfo,
                remove: false
              })
            }
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
          >
            <DialogTitle
              id="notice-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() =>
                  setRemoveVoucherInfo({
                    ...removeVoucherInfo,
                    remove: false
                  })
                }
              >
                <Close className={classes.modalClose} />
              </Button>
              <h4 className={classes.modalTitle}>Remove Voucher?</h4>
            </DialogTitle>
            <DialogContent
              id="notice-modal-slide-description"
              className={classes.modalBody}
            >
              <CustomInput
                labelText="No"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value:
                    removeVoucherInfo.passenger.identification.no === null
                      ? ""
                      : removeVoucherInfo.passenger.identification.no
                }}
              />
              <CustomInput
                labelText="FullName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value:
                    removeVoucherInfo.passenger.identification.fullName === null
                      ? ""
                      : removeVoucherInfo.passenger.identification.fullName
                }}
              />
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                onClick={() =>
                  setRemoveVoucherInfo({
                    ...removeVoucherInfo,
                    remove: false
                  })
                }
                round
              >
                Cancel
              </Button>
              <Button
                disabled={disabled}
                onClick={handleRemoveVoucher}
                color="danger"
                className="remove"
                round
              >
                <DeleteIcon /> Remove
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {removePassenger !== null && (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal
            }}
            open={removePassenger !== null}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setRemovePassenger(null)}
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
          >
            <DialogTitle
              id="notice-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setRemovePassenger(null)}
              >
                <Close className={classes.modalClose} />
              </Button>
              <h4 className={classes.modalTitle}>Delete this Passenger?</h4>
            </DialogTitle>
            <DialogContent
              id="notice-modal-slide-description"
              className={classes.modalBody}
            >
              <CustomInput
                label={<b>Passport No</b>}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value:
                    removePassenger.identification.no === null
                      ? ""
                      : removePassenger.identification.no
                }}
              />
              <CustomInput
                labelText="FullName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value:
                    removePassenger.identification.fullName === null
                      ? ""
                      : removePassenger.identification.fullName
                }}
              />
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button onClick={() => setRemovePassenger(null)} round>
                Cancel
              </Button>
              <Button
                disabled={disabled}
                onClick={handleDelete}
                color="danger"
                className="remove"
                round
              >
                <DeleteIcon /> Delete
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {booking.status === 0 && (
          <form onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="PASSPORT NO"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleNoChanged,
                    value: state.no === null ? "" : state.no
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="FULL NAME"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleFullNameChanged,
                    value: state.fullName === null ? "" : state.fullName
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selGender"
                    className={classes.selectLabel}
                  >
                    <b>Gender</b>
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={state.gender === null ? "" : state.gender}
                    onChange={handleGenderChanged}
                    inputProps={{
                      name: "selGender",
                      id: "selGender"
                    }}
                  >
                    {GetGenders().map(record => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={record.key}
                          key={record.key}
                        >
                          {record.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <InputLabel className={classes.selectLabel}>
                  <b>Date of Birth</b>
                </InputLabel>
                <FormControl fullWidth className={classes.datetime}>
                  <Datetime
                    value={state.dateOfBirth}
                    onChange={handleDateOfBirthChanged}
                    dateFormat={dateFormat}
                    timeFormat={false}
                    utc={true}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: dateFormat
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Autocomplete
                  id="nationality"
                  value={
                    autocompleteCache.nationality === null
                      ? ""
                      : autocompleteCache.nationality
                  }
                  onChange={handleNationalityIDChanged}
                  options={countryList}
                  classes={{
                    option: classes.option
                  }}
                  className={classes.autoComplete}
                  autoHighlight
                  getOptionLabel={option =>
                    option?.nationality === null ||
                    option?.nationality === undefined
                      ? ""
                      : option.nationality
                  }
                  renderOption={option => (
                    <React.Fragment>
                      <b>{option.code}</b> - {option.nationality}
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={<b>NATIONALITY</b>}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Autocomplete
                  id="issuanceCountry"
                  value={
                    autocompleteCache.issuanceCountry === null
                      ? ""
                      : autocompleteCache.issuanceCountry
                  }
                  onChange={handleIssuanceCountryIDChanged}
                  options={countryList}
                  classes={{
                    option: classes.option
                  }}
                  className={classes.autoComplete}
                  autoHighlight
                  getOptionLabel={option =>
                    option?.name === null || option?.name === undefined
                      ? ""
                      : option.name
                  }
                  renderOption={option => (
                    <React.Fragment>
                      <b>{option.code}</b> - {option.name}
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={<b>ISSUANCE COUNTRY</b>}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <InputLabel className={classes.selectLabel}>
                  <b>Issue Date</b>
                </InputLabel>
                <FormControl fullWidth className={classes.datetime}>
                  <Datetime
                    value={state.issueDate}
                    onChange={handleIssueDateChanged}
                    dateFormat={dateFormat}
                    timeFormat={false}
                    utc={true}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: dateFormat
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <InputLabel className={classes.selectLabel}>
                  <b>Expiry Date</b> {expiryDateWarning()}
                </InputLabel>
                <FormControl fullWidth className={classes.datetime}>
                  <Datetime
                    value={state.expiryDate}
                    onChange={handleExpiryDateChanged}
                    dateFormat={dateFormat}
                    timeFormat={false}
                    utc={true}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: dateFormat
                    }}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                {editId ? (
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <Button
                        disabled={disabled}
                        color="rose"
                        type="submit"
                        value="Submit"
                        fullWidth
                      >
                        Update Passenger
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <Button
                        disabled={disabled}
                        color="warning"
                        type="submit"
                        fullWidth
                        onClick={() => {
                          setEditId(null);
                          setState({ ...defaultState });
                        }}
                      >
                        Cancel Edit
                      </Button>
                    </GridItem>
                  </GridContainer>
                ) : (
                  <Button
                    fullWidth
                    disabled={disabled}
                    color="rose"
                    type="submit"
                    value="Submit"
                  >
                    Add Passenger
                  </Button>
                )}
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                {switchViewButton}
              </GridItem>
            </GridContainer>
          </form>
        )}
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
              labelText="Search"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: handleSearchStringChanged,
                value: filter.searchString === null ? "" : filter.searchString
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <Button
              fullWidth
              disabled={disabled}
              color="rose"
              onClick={() =>
                setUpload({
                  uploading: true,
                  file: null
                })
              }
            >
              Import From Excel
            </Button>
          </GridItem>
        </GridContainer>
        <ReactTable
          manual
          pages={pages}
          page={pagination.pageIndex - 1}
          pageSize={pagination.pageSize}
          onPageChange={page =>
            setPagination({
              pageIndex: page + 1,
              pageSize: pagination.pageSize
            })
          }
          onPageSizeChange={(pageSize, page) => {
            setPagination({ pageSize: pageSize, pageIndex: page + 1 });
          }}
          loading={loading}
          data={data}
          filterable={false}
          columns={
            tableMode === "passenger" ? passengerColumns : voucherColumns
          }
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
        />
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            {switchViewButton}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

PassengerList.propTypes = {
  booking: PropTypes.object,
  currency: PropTypes.string
};
