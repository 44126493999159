import React from "react";
import { Link } from "react-router-dom";
// react plugin for creating vector maps

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import VisibilityIcon from "@material-ui/icons/Visibility";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  /*
  const openLink = (link) => {
    window.open(link,'_blank');
  }
  */
  return (
    <div>
      <h3>Hot Deals</h3>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <Link to={"/admin/promos/productpowersalechallenge"}>
                <img
                  src={"/dashboard/202201162014_product_power_sale.jpg"}
                  alt="Product Power Sale Challenge"
                />
              </Link>
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>
                Product Power Sale Challenge
              </h4>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                <Link to={"/admin/promos/productpowersalechallenge"}>
                  Learn More...
                </Link>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <Link to={"/admin/promos/tourguidesupportingfund"}>
                <img
                  src={"/dashboard/202201162018_tour_guide_supporting_fund.jpg"}
                  alt="Tour Guide Supporting Fund"
                />
              </Link>
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>
                Tour Guide Supporting Fund
              </h4>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                <Link to={"/admin/promos/tourguidesupportingfund"}>
                  Learn More...
                </Link>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img
                src={"/dashboard/202111161600-royal_tea_set.jpg"}
                alt="Royal Tea Set"
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>
                Marina Coastal Delights - Kueh & Kusu Royal Tea Set
              </h4>
              <p className={classes.cardProductDesciprion}>
                Get to enjoy the local delicacies such as Ondeh-Ondeh and Pulut
                Tai Tai, and tickle your tastebuds with our newly added Chicken
                Vol-Au-Vent and Smoked Duck Confit.
              </p>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                {
                  <a
                    href="#dashboard"
                    onClick={e => {
                      e.preventDefault();
                      window
                        .open(
                          "https://www.sindoferry.com.sg/kueh-kusu-royal-tea-set",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    Learn More...
                  </a>
                }
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img
                src={"/dashboard/202111101459-beach_breakfast.jpg"}
                alt="Beach & Breakfast"
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>
                Marina Coastal Delights - Beach & Breakfast
              </h4>
              <p className={classes.cardProductDesciprion}>
                Not sure where to spend your family vacation? claustrophobic at
                home? Tired of staying at home? Feeling restless? Come join us
                aboard our Beach & Breakfast ferry, the first and only breakfast
                (Halal certified) ferry experience in Singapore!
              </p>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                {
                  <a
                    href="#dashboard"
                    onClick={e => {
                      e.preventDefault();
                      window
                        .open(
                          "https://www.sindoferry.com.sg/beach-breakfast",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    Learn More...
                  </a>
                }
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <Link to={"/admin/promos/rediscover"}>
                <img
                  src={"/dashboard/202011171118_rediscoversingapore.webp"}
                  alt="Rediscover Singapore"
                />
              </Link>
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>Rediscover Singapore</h4>
              <p className={classes.cardProductDesciprion}>
                Rediscover Singapore with Sindo Ferry. You can redeem your
                vouchers and confirm your bookings here. Simply provide
                passenger details and select your travel date. Limited seats are
                available per trip. Book early to secure your seats!
              </p>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                {
                  <a
                    href="#dashboard"
                    onClick={e => {
                      e.preventDefault();
                      window
                        .open(
                          "https://www.sindoferry.com.sg/singapore-discovery-journey",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    Learn More...
                  </a>
                }
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img
                src={"/dashboard/202106011944_marina_coastal_delights.jpg"}
                alt="Marina Coastal Delights"
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>
                Marina Coastal Delights - Sunset Cruise
              </h4>
              <p className={classes.cardProductDesciprion}>
                Singapore’s first ferry dining experience departing from
                Harbourfront Centre. Uniquely romantic and nostalgic two-hours
                cruise along our Marina coastline and Sentosa. 360-degree views
                of Singapore iconic landmarks.
              </p>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                {
                  <a
                    href="#dashboard"
                    onClick={e => {
                      e.preventDefault();
                      window
                        .open(
                          "https://www.sindoferry.com.sg/marina-coastal-delights",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    Learn More...
                  </a>
                }
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img
                src={"/dashboard/202106011944_sunset_voyage.jpg"}
                alt="Sunset Voyage"
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>
                Singapore Sunset Voyage
              </h4>
              <p className={classes.cardProductDesciprion}>
                Explore Saint John’s Island and Lazarus Island and fill it with
                fun activities with family and friends. Grab your sense of
                adventure and cruise along Marina South Coast. Experience and
                gaze at the beautiful sunset that will captivate you.
              </p>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                {
                  <a
                    href="#dashboard"
                    onClick={e => {
                      e.preventDefault();
                      window
                        .open(
                          "https://www.sindoferry.com.sg/singapore-sunset-voyage",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    Learn More...
                  </a>
                }
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img
                src={"/dashboard/202106152243_kueh_kusu.jpg"}
                alt="Kueh Kusu"
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardProductTitle}>
                Marina Coastal Delights - Kueh & Kusu
              </h4>
              <p className={classes.cardProductDesciprion}>
                Make your trip to Kusu island even more magical with our
                afternoon tea ferry experience (first and the only one in
                Singapore). Definitely one of the most insta-worthy afternoon
                teas in Singapore.
              </p>
            </CardBody>
            <CardFooter product>
              {/*
                <div className={classes.price}>
                  <h4></h4>
                </div>
                */}
              <div className={`${classes.stats} ${classes.productStats}`}>
                <VisibilityIcon />
                {
                  <a
                    href="#dashboard"
                    onClick={e => {
                      e.preventDefault();
                      window
                        .open(
                          "https://www.sindoferry.com.sg/marina-coastal-delights",
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    Learn More...
                  </a>
                }
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
